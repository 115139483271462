import React from 'react';
import { Box, Checkbox, IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../../../utils/formatPrice';

export const RowBillingPproduct = ({ item, handleItemSelected, handleEditArticle, handleDeleteArticle, isRoleCargaPrecio }: any) => {
    const { t } = useTranslation();

    return (
        <>
            <TableRow sx={{ boxShadow: 2, textAlign: 'center' }} className="table__mainRow">
                <TableCell className="table__cellAux">
                    <Table>
                        <TableBody>
                            <TableRow className="table__row table__row_billing_product" hover={true} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell className="table__cell table__controlCell">
                                    <Checkbox checked={item?.checked} onChange={(e) => handleItemSelected(e, item)} />
                                </TableCell>
                                <TableCell className="table__billing_product_CellBody">{item.id}</TableCell>
                                <TableCell className="table__billing_product_CellBody">{item.articulo}</TableCell>
                                <TableCell className="table__billing_product_CellBody">{item.descripcionTipoModalidad}</TableCell>
                                <TableCell className="table__billing_product_CellBody">{item.tipoTicket}</TableCell>
                                <TableCell className="table__billing_product_CellBody">{item.descripcionCarrera}</TableCell>
                                <TableCell className="table__billing_product_CellBody">
                                    {item.turnoCursado} - {item.turnoCursadoDescripcion}
                                </TableCell>
                                <TableCell className="table__billing_product_CellBody">{item.uniOp}</TableCell>
                                <TableCell className="table__billing_product_CellBody">{formatPrice(item.precio)}</TableCell>
                                <TableCell className="table__cell table__controlCell">
                                    <Tooltip style={{ paddingInline: '5px' }} title={t('tooltips.editLog')}>
                                        <Box>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                id="rmt-syllabusEdit"
                                                onClick={() => handleEditArticle(item)}
                                                disabled={!isRoleCargaPrecio}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className="table__cell table__controlCell">
                                    <Tooltip style={{ paddingInline: '5px' }} title={t('tooltips.delete')}>
                                        <Box>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                id="rmt-syllabusEdit"
                                                onClick={() => handleDeleteArticle(item)}
                                                disabled={!isRoleCargaPrecio}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    );
};
