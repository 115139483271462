import { axiosInstance } from './api.service';

export const bulkLoadService = {
    fileUpload: (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        return axiosInstance.post('/articles/file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getRecentLots: async () => {
        const { data } = await axiosInstance.get('/lotes/lotesRecientes');
        return data;
    },
    getArticlesLoads: async (page: number, pageSize: number = 10, filters?: any) => {
        const params = {
            idCsv: filters.csvId,
            idCarrera: filters.careerId,
            idCau: filters.cauId,
            idTipoModalidad: filters.modalityId,
            periodoAcademico: filters.periodId,
            tipoArancelId: filters.arancelId,
            idTipoTicket: filters.ticketId,
            turnoCursado: filters.turnoId,
            tipoAlumno: filters.studentTypeId,
            idEstado: filters.statusId,
            fechaMax: filters.endDate,
            fechaMin: filters.startDate,
            pageNo: page.toString(),
            pageSize: pageSize.toString()
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.get(`/csv/current-records?${query}`);
        return data;
    },
    getFilterLotes: async (page: number, pageSize: number = 10, filters?: any) => {
        const params = {
            idLote: filters.idLote,
            nombreArchivo: filters.nombreArchivo,
            fechaMin: filters.fechaMin,
            fechaMax: filters.fechaMax,
            pageNo: page.toString(),
            pageSize: pageSize.toString()
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();

        const { data } = await axiosInstance.get(`/lotes/filterLotes?${query}`);
        return data;
    },
    editLog: async function (editValues: any) {
        const { data } = await axiosInstance.put('/csv', editValues);
        return data;
    },
    existPriceActive: async function (articles: any) {
        const { data } = await axiosInstance.post('/articles/existe/', articles);
        return data;
    },
    approvePrice: async function (articles: any) {
        const { data } = await axiosInstance.post('/articles/confirmarPrecio/', articles);
        return data;
    },
    rejectPrice: async function (articles: any) {
        const { data } = await axiosInstance.put('/csv/rejectArticleCsv', articles);
        return data;
    },
    eliminatePrice: async function (articles: any) {
        const { data } = await axiosInstance.put('/csv/unsubscribeArticleCsv', articles);
        return data;
    },
    rejectAll: async function (csvId: number, articlesIds: number[], filters?: any) {
        const params = {
            csvId,
            idCarrera: filters.careerId,
            idCau: filters.cauId,
            idTipoModalidad: filters.modalityId,
            tipoArancelId: filters.arancelId,
            idTipoTicket: filters.ticketId,
            turno: filters.periodId,
            turnoCursado: filters.turnoId,
            tipoAlumno: filters.studentTypeId,
            idEstado: filters.statusId,
            fechaFin: filters.endDate,
            fechaInicio: filters.startDate
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.put(`/csv/rejectAll?${query}`, articlesIds);
        return data;
    },
    deleteAll: async function (csvId: number, articlesIds: number[], filters?: any) {
        const params = {
            csvId,
            idCarrera: filters.careerId,
            idCau: filters.cauId,
            idTipoModalidad: filters.modalityId,
            tipoArancelId: filters.arancelId,
            idTipoTicket: filters.ticketId,
            turno: filters.periodId,
            turnoCursado: filters.turnoId,
            tipoAlumno: filters.studentTypeId,
            idEstado: filters.statusId,
            fechaFin: filters.endDate,
            fechaInicio: filters.startDate
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.put(`/csv/deleteAll?${query}`, articlesIds);
        return data;
    },
    approveAll: async function (csvId: number, articlesIds: number[], filters?: any) {
        const params = {
            csvId,
            idCarrera: filters.careerId,
            idCau: filters.cauId,
            idTipoModalidad: filters.modalityId,
            tipoArancelId: filters.arancelId,
            idTipoTicket: filters.ticketId,
            turno: filters.periodId,
            turnoCursado: filters.turnoId,
            tipoAlumno: filters.studentTypeId,
            idEstado: filters.statusId,
            fechaFin: filters.endDate,
            fechaInicio: filters.startDate
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.post(`/articles/approveAll?${query}`, articlesIds);
        return data;
    },
    existeAll: async function (csvId: number, articlesIds: number[]) {
        const { data } = await axiosInstance.post(`/articles/existeAll?csvId=${csvId}`, articlesIds);
        return data;
    },
    validArticleExist: async function (article: any) {
        const { data } = await axiosInstance.post('/csv/existe', article);
        return data;
    }
};
