import React, { useRef, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter, CircularProgress, TablePagination, Button, Checkbox } from '@mui/material';
import RowTableHistorical from './row-table-historical/row-table-historical';
import TablePaginationActions from '../../../shared/pagination/TablePaginationActions';
import TableMessage from '../../../shared/tableMessage/TableMessage';
import { Download as DownloadIcon } from '@mui/icons-material';
import { CSVLink } from 'react-csv';

type historicalT = {
    historicalData: any;
    isLoading: boolean;
    isFetching: boolean;
    totalElements: number;
    pageSize: number;
    page: number;
    handlePage: Function;
    isCheckedAll: boolean;
    isPartialCheck: boolean;
    handleCheckedAll: any;
    handleDownloadCsvAPI: any;
    articlesSelecteds: any;
    handleItemSelected: any;
    articles: any;
}

const TableHistorical = ({ historicalData, isLoading, isFetching, totalElements, pageSize, page, handlePage, isCheckedAll, isPartialCheck, handleCheckedAll, handleDownloadCsvAPI, articlesSelecteds, handleItemSelected, articles } : historicalT) => {
    const { t } = useTranslation();
    const refs = useRef<any | null>(null);
    const [showNoResultsMessage, setShowNoResultsMessage] = useState<boolean>(false);

    const headers = [
        { label: 'CANTIDAD_DE_MATERIAS', key: 'amountCourse' },
        { label: 'CARRERA', key: 'idProgram' },
        { label: 'CAU', key: 'cau' },
        { label: 'CB_OI', key: 'cb_oi' },
        { label: 'FECHA_APROBACION', key: 'approvalDate' },
        { label: 'MODALIDAD', key: 'modality' },
        { label: 'MONEDA', key: 'money' },
        { label: 'PORCENTAJE', key: 'percentage' },
        { label: 'PRECIO', key: 'price' },
        { label: 'TIPO_ARANCEL', key: 'idTypeArancel' },
        { label: 'TIPO_DE_ALUMNO', key: 'typeStudent' },
        { label: 'TIPO_DE_TICKET', key: 'typeTicket' },
        { label: 'TIPO_MODALIDAD', key: 'idTypeModality' },
        { label: 'TURNO', key: 'turn' },
        { label: 'TURNO_CURSADO_CARRERA', key: 'turnCourse' }
    ];

    useEffect(() => {
        setShowNoResultsMessage(false);
        const timer = setTimeout(() => {
            if (!isLoading && !isFetching) {
                setShowNoResultsMessage(true);
            }
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [isLoading, isFetching]);

    if (isLoading || isFetching || articles?.length === 0) {
        if (!showNoResultsMessage) {
            return (
                <div className="flex-justify-content_center">
                    <CircularProgress id="rmt-loadingPrograms" color="success" />
                </div>
            );
        }
        if (articles?.length === 0) return <TableMessage msg={t('messages.resultsNotFount')} />;
    }
    /* if (isLoading || isFetching) {
        return (
            <div className="flex-justify-content_center">
                <CircularProgress id="rmt-loadingPrograms" color="success" />
            </div>
        );
    }
    if (articles?.length === 0) return <TableMessage msg={t('messages.resultsNotFount')} />; */

    const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, currenPage: number) => {
        handlePage(currenPage);
    };

    const handleDownloadCSV = () => {
        if (!isCheckedAll) {
            refs.current.link.click();
        } else {
            handleDownloadCsvAPI();
        }
    };

    return (
        <>
            {(articlesSelecteds.some((item: any) => item?.checked) || isCheckedAll) && (
                <>
                    <Button variant="contained" type="button" startIcon={<DownloadIcon />} onClick={handleDownloadCSV}>
                        {t('buttons.download')}
                    </Button>
                    <CSVLink
                        data={articlesSelecteds.filter((item: any) => item.checked)}
                        headers={headers}
                        filename={'lista_precios_historico.csv'}
                        ref={refs}
                        separator={';'}
                        enclosingCharacter={''}
                        uFEFF={false}
                    />{' '}
                    {isCheckedAll ? (
                        <div className="mt-1">
                            <small>
                                <b>{totalElements - articlesSelecteds.filter((art: any) => !art.checked).length} registros seleccionados</b>
                            </small>
                        </div>
                    ) : (
                        <div className="mt-1">
                            <small>
                                <b>{articlesSelecteds.filter((item: any) => item.checked).length} registros seleccionados</b>
                            </small>
                        </div>
                    )}
                </>
            )}
            <TableContainer className="table__price_list" component={Paper}>
                <Table>
                    <TableBody className="table__body">
                        <TableRow className="table__historical_Header">
                            <TableCell className="tableCell__historical_Header">
                                <Checkbox color="default" checked={isCheckedAll} indeterminate={isPartialCheck} onChange={(e) => handleCheckedAll(e)} />
                            </TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.csv')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.career')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.cau')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.modality')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.period')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.ticket')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.Shift')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.typeStudent')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.status')}</TableCell>
                        </TableRow>
                        {articles?.map((item: any, i: number) => (
                            <RowTableHistorical key={i} item={item} handleItemSelected={handleItemSelected}/>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={totalElements || 0}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`;
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};
export default TableHistorical;
