import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Collapse, FormControl, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { currentPricesService } from '../../../../services/current-prices.service';
import { billingProductService } from '../../../../services/billing-product.service';

type BillingProductT = {
    careersData: any;
    career: any;
    setCareer: any;
    handleFilters: any;
    isAccordionOpen: any;
    setIsAccordionOpen: any;
    refetch: any;
};

export const FilterBillingProduct = ({
    careersData,
    career,
    setCareer,
    handleFilters,
    isAccordionOpen,
    setIsAccordionOpen,
    refetch
}: BillingProductT) => {
    const { t } = useTranslation();
    const [identificador, setidentificador] = useState<any>(null);
    const [article, setarticle] = useState<any>(null);
    const [studyClass, setStudyClass] = useState<any>(null);
    const [modality, setModality] = useState<any>(null);
    const [modalityType, setModalityType] = useState<any>(null);
    const [ticket, setTicket] = useState<any>(null);
    const [turno, setTurno] = useState<any>(null);
    const [unidOp, setUnidOp] = useState<any>(null);
    const [isDisabled, setisDisabled] = useState(true);

    //! useQuery
    const { data: studyClassData } = useQuery('studyClassBP', billingProductService.getStudyClassCalipso, {
        refetchOnWindowFocus: false
    });

    const { data: modalitiesData } = useQuery(
        ['modalitiBP', modalityType],
        async () => {
            const articles = await billingProductService.getModalityType(modalityType);
            return articles;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const { data: ticketsData } = useQuery('ticketsBP', currentPricesService.getTickets, {
        refetchOnWindowFocus: false
    });

    const { data: turnosData } = useQuery('typeCoursesBP', currentPricesService.getTurns, {
        refetchOnWindowFocus: false
    });
    const { data: unidOpData } = useQuery('unidOpBP', billingProductService.getOperativeUnit, {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (career) {
            setIsAccordionOpen(true);
        } else {
            setIsAccordionOpen(false);
        }
        handleSubmit();
    }, [career]);

    useEffect(() => {
        setModality(null);
        if (studyClass) {
            setModalityType(studyClass.modalidad);
            setisDisabled(false);
        }
    }, [studyClass]);

    //! Botón Submit
    const handleSubmit = () => {
        handleFilters({
            idCarrera: career?.id,
            identificador,
            article,
            modalidad: studyClass?.modalidad,
            ticket: ticket?.description,
            unidOp: unidOp?.id,
            idTipoModalidad: modality?.id,
            turno: turno?.id
        });
    };

    //! Limpieza de filtros
    const handleClean = () => {
        // setCareer(null);
        setStudyClass(null);
        setModality(null);
        setTicket(null);
        setTurno(null);
        setUnidOp(null);
        setidentificador('');
        setarticle('');
        handleFilters({
            // idCarrera: null,
            idCarrera: career?.id,
            identificador: null,
            article: null,
            modality: null,
            ticket: null,
            idTipoModalidad: null,
            unidOp: null,
            turno: null
        });
        refetch();
    };

    return (
        <>
            <form className="form_container">
                <Box className="form_box">
                    <FormControl size="small" className="form__control formCourse__matAutocomplete formControl_careers" id="rmt-Career">
                        <Autocomplete
                            clearText={'Limpiar'}
                            openText=""
                            value={career}
                            options={careersData || []}
                            onChange={(e: any, newValue: any | null) => setCareer(newValue)}
                            disablePortal
                            noOptionsText={t('labels.noOptionsText')}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => `${option?.id} - ${option?.description}`}
                            renderInput={(params) => <TextField key={params.id} value={params.id} {...params} label={t('formFilter.career')} />}
                            renderOption={(props, option: any) => (
                                <li {...props} value={option?.id} key={option?.id}>
                                    {`${option?.id} - ${option?.description}`}
                                </li>
                            )}
                        />
                    </FormControl>
                </Box>

                <Collapse className="" in={isAccordionOpen} timeout="auto" unmountOnExit>
                    <Box className="filter__prices">
                        <Box className="filter__control">
                            <Box className="filter__line filter__body_billing_product_line_1">
                                <FormControl size="small" className="form__control" id="rmt-Cau">
                                    <TextField
                                        label="Identificador"
                                        id="outlined-size-small"
                                        size="small"
                                        type="text"
                                        value={identificador || ''}
                                        onChange={(ev: any) => setidentificador(ev.target.value)}
                                    />
                                </FormControl>
                                <FormControl size="small" className="form__control" id="rmt-Cau">
                                    <TextField
                                        label="Artículo"
                                        id="outlined-size-small"
                                        size="small"
                                        type="text"
                                        value={article || ''}
                                        onChange={(ev: any) => setarticle(ev.target.value)}
                                    />
                                </FormControl>
                                <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-studyClass">
                                    <Autocomplete
                                        clearText={'Limpiar'}
                                        openText=""
                                        value={unidOp}
                                        options={unidOpData || []}
                                        onChange={(e: any, newValue: any | null) => setUnidOp(newValue)}
                                        disablePortal
                                        noOptionsText={t('labels.noOptionsText')}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option?.description}
                                        renderInput={(params) => (
                                            <TextField key={params.id} value={params.id} {...params} label={t('formFilter.unidOp')} />
                                        )}
                                        renderOption={(props, option: any) => (
                                            <li {...props} value={option?.id} key={option?.id}>
                                                {option?.description}
                                            </li>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                            <Box className="filter__line filter__body_billing_product_line_2">
                                <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-studyClass">
                                    <Autocomplete
                                        clearText={'Limpiar'}
                                        openText=""
                                        value={studyClass}
                                        options={studyClassData || []}
                                        onChange={(e: any, newValue: any | null) => setStudyClass(newValue)}
                                        disablePortal
                                        noOptionsText={t('labels.noOptionsText')}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option?.description}
                                        renderInput={(params) => (
                                            <TextField key={params.id} value={params.id} {...params} label={t('formFilter.modality')} />
                                        )}
                                        renderOption={(props, option: any) => (
                                            <li {...props} value={option?.id} key={option?.id}>
                                                {option?.description}
                                            </li>
                                        )}
                                    />
                                </FormControl>
                                <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Modality">
                                    <Autocomplete
                                        disabled={isDisabled}
                                        clearText={'Limpiar'}
                                        openText=""
                                        value={modality}
                                        options={modalitiesData || []}
                                        onChange={(e: any, newValue: any | null) => setModality(newValue)}
                                        disablePortal
                                        noOptionsText={t('labels.noOptionsText')}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option?.description}
                                        renderInput={(params) => (
                                            <TextField key={params.id} value={params.id} {...params} label={t('formFilter.submodality')} />
                                        )}
                                        renderOption={(props, option: any) => (
                                            <li {...props} value={option?.id} key={option?.id}>
                                                {option?.description}
                                            </li>
                                        )}
                                    />
                                </FormControl>
                                <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Ticket">
                                    <Autocomplete
                                        clearText={'Limpiar'}
                                        openText=""
                                        value={ticket}
                                        options={ticketsData || []}
                                        onChange={(e: any, newValue: any | null) => setTicket(newValue)}
                                        disablePortal
                                        noOptionsText={t('labels.noOptionsText')}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option?.description}
                                        renderInput={(params) => (
                                            <TextField key={params.id} value={params.id} {...params} label={t('formFilter.ticket')} />
                                        )}
                                        renderOption={(props, option: any) => (
                                            <li {...props} value={option?.id} key={option?.id}>
                                                {option?.description}
                                            </li>
                                        )}
                                    />
                                </FormControl>
                                <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Cursando">
                                    <Autocomplete
                                        clearText={'Limpiar'}
                                        openText=""
                                        value={turno}
                                        options={turnosData || []}
                                        onChange={(e: any, newValue: any | null) => setTurno(newValue)}
                                        disablePortal
                                        noOptionsText={t('labels.noOptionsText')}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => `${option?.id} - ${option?.description}`}
                                        renderInput={(params) => (
                                            <TextField key={params.id} value={params.id} {...params} label={`${t('formFilter.cursando')}`} />
                                        )}
                                        renderOption={(props, option: any) => (
                                            <li {...props} value={option?.id} key={option?.id}>
                                                {option?.id} - {option?.description}
                                            </li>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <div className="filter__buttons">
                            <Button variant="contained" type="button" startIcon={<Search />} onClick={handleSubmit}>
                                {t('buttons.search')}
                            </Button>
                            <Button type="button" className="button-clean-filter" onClick={handleClean} id="rmt-cleanButton">
                                {t('buttons.cleanFilters')}
                            </Button>
                        </div>
                    </Box>
                </Collapse>
            </form>
        </>
    );
};
