import React, { useEffect, useState, useContext } from 'react';
import './billing-produc.scss';
import Toolbar from '../../layout/Toolbar/Toolbar';
import { Button, Box, Typography } from '@mui/material';
import { FilterBillingProduct } from './filter-billing-product/filter-billing-product';
import { useTranslation } from 'react-i18next';
import { TableBillingProduct } from './table-billing-product/table-billing-product';
import { AppContext } from '../../../App.context';
import { appReducerCases } from '../../../reducers/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { billingProductService } from '../../../services/billing-product.service';
import { currentPricesService } from '../../../services/current-prices.service';
import AddBillingProduct from './add-billing-product/add-billing-product';
import { hasRole } from '../../../utils/utils';
import { AUTHORITIES } from '../../../utils/constants';
import PostAddIcon from '@mui/icons-material/PostAdd';

export const BillingProduct = () => {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const user = appContext?.state.user;
    const [filters, setFilters] = useState<any>({});
    const [totalElements, setTotalElements] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const pageSize = 10;
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [career, setCareer] = useState<any>(null);
    const [isPartialCheck, setPartialCheck] = useState(false);
    const [isCheckedAll, setCheckedAll] = useState(false);
    const [listCarrers, setlistCarrers] = useState<any[]>([]);
    const [selecteds, setSelecteds] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [article, setArticle] = useState<any>(null);
    const queryClient = useQueryClient();
    const [isRoleCargaPrecio, setisRoleCargaPrecio] = useState(false);

    const { data: careersData } = useQuery(
        'careersH',
        async () => {
            const data = await currentPricesService.getCareers();
            return data?.map((item: any) => ({ id: item.id, description: item.description }));
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const {
        data: billingProductData,
        isFetching,
        isLoading,
        refetch
    } = useQuery(
        ['articlesBP', page, pageSize, filters],
        async () => {
            const articles = await billingProductService.getBillingProductlData(page, pageSize, filters);
            return articles;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const handleFilters = (filters: any) => {
        setFilters(filters);
        setPage(0);
    };
    const handlePage = (page: number) => {
        setPage(page);
    };

    useEffect(() => {
        if (billingProductData) {
            const data = billingProductData?.codigosCalipso.map((item: any) => {
                const result = selecteds.find(
                    (elem) =>
                        elem.id === item.id &&
                        elem.idCarrera === item.idCarrera &&
                        elem.articulo === item.articulo &&
                        elem.tipoModalidad === item.tipoModalidad &&
                        elem.modality === item.modality &&
                        elem.tipoTicket === item.tipoTicket &&
                        elem.turnoCursado === item.turnoCursado &&
                        elem.descripcionCarrera === item.descripcionCarrera &&
                        elem.turnoCursadoDescripcion === item.turnoCursadoDescripcion
                );
                if (result) {
                    return { ...item, checked: result.checked };
                }
                return { ...item, checked: false };
            });
            setPartialCheck(data.some((item: any) => item?.checked));
            setCheckedAll(data.every((item: any) => item?.checked));
            setlistCarrers(data);
            setTotalElements(billingProductData?.totalElementos);
        }
    }, [billingProductData]);

    useEffect(() => {
        setisRoleCargaPrecio(hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS]));
    }, []);

    const handleItemSelected = (e: any, item: any) => {
        const checked = e.target.checked;
        const newList = listCarrers.map((elem) => {
            if (
                elem.id === item.id &&
                elem.idCarrera === item.idCarrera &&
                elem.articulo === item.articulo &&
                elem.tipoModalidad === item.tipoModalidad &&
                elem.modality === item.modality &&
                elem.tipoTicket === item.tipoTicket &&
                elem.turnoCursado === item.turnoCursado &&
                elem.turnoCursado === item.turnoCursado &&
                elem.descripcionCarrera === item.descripcionCarrera &&
                elem.turnoCursadoDescripcion === item.turnoCursadoDescripcion
            ) {
                return { ...elem, checked };
            }
            return { ...elem };
        });

        const selectedItemIndex = selecteds.findIndex(
            (elem) =>
                elem.id === item.id &&
                elem.idCarrera === item.idCarrera &&
                elem.articulo === item.articulo &&
                elem.tipoModalidad === item.tipoModalidad &&
                elem.modality === item.modality &&
                elem.tipoTicket === item.tipoTicket &&
                elem.turnoCursado === item.turnoCursado &&
                elem.turnoCursado === item.turnoCursado &&
                elem.descripcionCarrera === item.descripcionCarrera &&
                elem.turnoCursadoDescripcion === item.turnoCursadoDescripcion
        );

        if (!checked) {
            if (selectedItemIndex > -1) {
                selecteds.splice(selectedItemIndex, 1);
                setSelecteds([...selecteds]);
            }
        } else {
            if (selectedItemIndex > -1) {
                selecteds[selectedItemIndex].checked = true;
                setSelecteds([...selecteds]);
            } else {
                setSelecteds([...selecteds, { ...item, checked: true }]);
            }
        }

        const atLeastChecked = newList.some((item) => item?.checked);
        setPartialCheck(atLeastChecked);
        setlistCarrers(newList);
    };

    const handleCheckedAll = (e: any) => {
        const checked = e.target.checked;
        const newList = listCarrers.map((item: any) => ({ ...item, checked }));

        const newSelecteds = checked
            ? [
                ...selecteds,
                ...newList.filter(
                    (item) =>
                        !selecteds.some(
                            (selItem) =>
                                item.id === selItem.id &&
                                item.idCarrera === selItem.idCarrera &&
                                item.articulo === selItem.articulo &&
                                item.tipoModalidad === selItem.tipoModalidad &&
                                item.modality === selItem.modality &&
                                item.tipoTicket === selItem.tipoTicket &&
                                item.turnoCursado === selItem.turnoCursado &&
                                item.descripcionCarrera === selItem.descripcionCarrera &&
                                item.turnoCursadoDescripcion === selItem.turnoCursadoDescripcion
                        )
                )
            ]
            : selecteds.filter(
                (item) =>
                    !newList.some(
                        (newItem) =>
                            item.id === newItem.id &&
                            item.idCarrera === newItem.idCarrera &&
                            item.articulo === newItem.articulo &&
                            item.tipoModalidad === newItem.tipoModalidad &&
                            item.modality === newItem.modality &&
                            item.tipoTicket === newItem.tipoTicket &&
                            item.turnoCursado === newItem.turnoCursado &&
                            item.descripcionCarrera === newItem.descripcionCarrera &&
                            item.turnoCursadoDescripcion === newItem.turnoCursadoDescripcion
                    )
            );
        setPartialCheck(false);
        setCheckedAll(checked);
        setlistCarrers(newList);
        setSelecteds(newSelecteds);
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    const newArticle = () => {
        setArticle(null);
        handleOpenModal();
    };

    const handleEditArticle = (item: any) => {
        setArticle(item);
        handleOpenModal();
    };

    const handleDeleteArticle = (item: any) => {
        const req = {
            id: item.id,
            idProgram: item.idCarrera,
            article: item.articulo,
            modality: item.modality,
            idTypeModality: item.tipoModalidad,
            typeTicket: item.tipoTicket,
            turn: item.turnoCursado
        };
        confirmDelete(req);
    };

    const confirmDelete = (billingProduct: any) => {
        appContext.dispatch({
            type: appReducerCases.openAlert,
            payload: t('messages.confirmDelete')
        });
        appContext.dispatch({
            type: appReducerCases.setAlertConfirmAction,
            payload: () => {
                deleteArticle(billingProduct);
            }
        });
        appContext.dispatch({
            type: appReducerCases.setAlertCancelAction,
            payload: () => {
                appContext.dispatch({
                    type: appReducerCases.closeAlert
                });
            }
        });
    };

    const deleteArticleCall = async (billingProduct: any) => {
        const data = await billingProductService.deleteBillingProduct(billingProduct);
        return data;
    };

    const { mutate: deleteArticle } = useMutation(deleteArticleCall, {
        onSuccess: (data: any) => {
            cleanCheckboxs();
            refreshContent();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.deletedRecordSuccess')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: () => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const refreshContent = () => {
        queryClient.invalidateQueries(['articlesBP']);
    };

    const cleanCheckboxs = () => {
        setSelecteds([]);
        setPartialCheck(false);
        setCheckedAll(false);
    };

    return (
        <>
            <div className="layout__toolbar">
                <Toolbar />
            </div>
            <div className="container">
                <Box className="content_current_prices">
                    <Typography className="module_title" data-testid="module-title" gutterBottom>
                        {t('titles.billingProduct')}
                    </Typography>
                    <Typography className="module_subtitle1">{t('subtitles.billingProduct')}</Typography>
                </Box>
                <div className="create__controls">
                    <div className="create__line">
                        <FilterBillingProduct
                            careersData={careersData}
                            career={career}
                            setCareer={setCareer}
                            handleFilters={handleFilters}
                            isAccordionOpen={isAccordionOpen}
                            setIsAccordionOpen={setIsAccordionOpen}
                            refetch={refetch}
                        />
                    </div>
                </div>
                {isAccordionOpen && (
                    <Button variant="contained" component="label" onClick={newArticle} startIcon={<PostAddIcon />} disabled={!isRoleCargaPrecio}>
                        {t('buttons.newArticle')}
                    </Button>
                )}
                {isAccordionOpen && (
                    <Box>
                        <TableBillingProduct
                            listCarrers={listCarrers}
                            totalElements={totalElements}
                            pageSize={pageSize}
                            page={page}
                            handlePage={handlePage}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            isCheckedAll={isCheckedAll}
                            isPartialCheck={isPartialCheck}
                            handleItemSelected={handleItemSelected}
                            handleCheckedAll={handleCheckedAll}
                            selecteds={selecteds}
                            handleEditArticle={handleEditArticle}
                            handleDeleteArticle={handleDeleteArticle}
                            isRoleCargaPrecio={isRoleCargaPrecio}
                        />
                    </Box>
                )}
            </div>
            {isOpen && (
                <AddBillingProduct isOpen={isOpen} onCancel={closeModal} career={career} articleEdit={article} cleanCheckboxs={cleanCheckboxs} />
            )}
        </>
    );
};
