import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { currentPricesService } from '../../../../../services/current-prices.service';
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FilterInterface {
    handleFilters: any;
    setisTableOpen: any;
    modalityType: any;
    setModalityType: any;
    setisCheckedAllPrice: Function;
    isCheckedAllPrice: boolean;
    isRoleCargaPrecio?: boolean;
}

export const FilterExchangeTicketsPrice = ({
    handleFilters,
    setisTableOpen,
    modalityType,
    setModalityType,
    setisCheckedAllPrice,
    isCheckedAllPrice,
    isRoleCargaPrecio
}: FilterInterface) => {
    const { t } = useTranslation();

    const { data: modalitiesData } = useQuery('modalitiesET', currentPricesService.getModalities, {
        refetchOnWindowFocus: false
    });
    // console.log('Filtro 15 modalitiesData', modalitiesData);
    // console.log('FP20-modality', modality);
    // console.log('F25-modalityType', modalityType);

    useEffect(() => {
        if (modalityType) {
            setisTableOpen(true);
        } else {
            setisTableOpen(false);
        }
        handleSubmit();
    }, [modalityType]);

    //! Botón Submit
    const handleSubmit = () => {
        handleFilters({
            idModalidad: modalityType?.id
        });
    };
    return (
        <form className="filter__prices__tickets">
            <Box className="form_box">
                <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Modality">
                    <Autocomplete
                        clearText={'Limpiar'}
                        openText=""
                        value={modalityType}
                        options={modalitiesData || []}
                        onChange={(e: any, newValue: any | null) => setModalityType(newValue)}
                        disablePortal
                        noOptionsText={t('labels.noOptionsText')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.description}
                        renderInput={(params) => <TextField key={params.id} value={params.id} {...params} label={t('formFilter.submodality')} />}
                        renderOption={(props, option: any) => (
                            <li {...props} value={option?.id} key={option?.id}>
                                {option?.description}
                            </li>
                        )}
                    />
                </FormControl>
            </Box>
            <Box className="filter__checkbox__edit_all">
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={!modalityType || !isRoleCargaPrecio}
                            checked={isCheckedAllPrice}
                            onChange={(event) => setisCheckedAllPrice(event.target.checked)}
                            name="editAllModalities"
                        />
                    }
                    label="Editar para todas las modalidades"
                />
            </Box>
        </form>
    );
};
