import React, { useState, useEffect, useContext } from 'react';
import Modal from '@mui/material/Modal';
import { FormControl, Typography, Box, TextField, Stack, Button, Autocomplete, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { currentPricesService } from '../../../../services/current-prices.service';
import { billingProductService } from '../../../../services/billing-product.service';
import { AppContext } from '../../../../App.context';
import { appReducerCases } from '../../../../reducers/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const AddBillingProduct = ({ isOpen, onCancel, career, articleEdit, cleanCheckboxs }: any) => {
    const { t } = useTranslation();
    const [identificador, setidentificador] = useState<any>(null);
    const [article, setarticle] = useState<any>(null);
    const [unidOp, setUnidOp] = useState<any>(null);
    const [studyClass, setStudyClass] = useState<any>(null);
    const [modality, setModality] = useState<any>(null);
    const [modalityType, setModalityType] = useState<any>(null);
    const [ticket, setTicket] = useState<any>(null);
    const [turno, setTurno] = useState<any>(null);
    const [isDisabled, setisDisabled] = useState(true);
    const appContext = useContext(AppContext);
    const queryClient = useQueryClient();

    useEffect(() => {
        setModality(null);
        if (studyClass) {
            setModalityType(studyClass.modalidad);
            setisDisabled(false);
        }
    }, [studyClass]);

    useEffect(() => {
        if (articleEdit) {
            setidentificador(articleEdit.id);
            setarticle(articleEdit.articulo);
        }
    }, []);

    const { data: studyClassData } = useQuery(
        'studyClassBP',
        async () => {
            const data = await billingProductService.getStudyClassCalipso();
            if (articleEdit) {
                setStudyClass(data.find((item: any) => item.modalidad === articleEdit.modality));
            }
            return data;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const { data: modalitiesData } = useQuery(
        ['modalitiBP', modalityType],
        async () => {
            const articles = await billingProductService.getModalityType(modalityType);
            if (articleEdit) {
                setModality(articles.find((item: any) => item.id === articleEdit.tipoModalidad.toString()) || null);
            }
            return articles;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const { data: ticketsData } = useQuery(
        'ticketsBP',
        async () => {
            const data = await currentPricesService.getTickets();
            if (articleEdit) {
                setTicket(data.find((item: any) => item.description === articleEdit.tipoTicket));
            }
            return data;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const { data: turnosData } = useQuery(
        'typeCoursesBP',
        async () => {
            const data = await currentPricesService.getTurns();
            if (articleEdit) {
                setTurno(data.find((item: any) => item.id === articleEdit.turnoCursado.toString()));
            }
            return data;
        },
        {
            refetchOnWindowFocus: false
        }
    );
    const { data: unidOpData } = useQuery(
        'unidOpBP',
        async () => {
            const data = await billingProductService.getOperativeUnit();
            if (articleEdit) {
                setUnidOp(data.find((item: any) => item.id === articleEdit.uniOp));
            }
            return data;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const onSubmit = () => {
        if (!articleEdit) {
            const req = {
                id: identificador,
                idProgram: career?.id,
                descriptionProgram: career?.description,
                article: article,
                modality: studyClass.modalidad,
                idTypeModality: modality && Number(modality.id),
                typeTicket: ticket?.description,
                turn: turno?.id,
                idOld: null,
                unidOp: unidOp?.id
            };
            createBillingProduct(req);
        } else {
            const req = {
                id: articleEdit.id,
                idProgram: articleEdit.idCarrera,
                article: articleEdit.articulo,
                modality: articleEdit.modality,
                idTypeModality: articleEdit.tipoModalidad,
                typeTicket: articleEdit.tipoTicket,
                turn: articleEdit.turnoCursado,
                idACambiar: identificador,
                modalityACambiar: studyClass.modalidad,
                articleACambiar: article,
                idTypeModalityACambiar: modality && Number(modality.id),
                typeTicketACambiar: ticket?.description,
                turnACambiar: turno?.id,
                unidOpACambiar: unidOp?.id
            };
            updateBillingProduct(req);
        }
    };

    const refreshList = () => {
        queryClient.invalidateQueries(['articlesBP']);
    };

    const createBillingProductCall = async (billingProduct: any) => {
        const data = await billingProductService.createBillingProduct(billingProduct);
        return data;
    };
    const { mutate: createBillingProduct, isLoading: isLoadingCreate } = useMutation(createBillingProductCall, {
        onSuccess: (res) => {
            onCancel();
            cleanCheckboxs();
            refreshList();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.recordCreatedSuccess')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const updateBillingProductCall = async (billingProduct: any) => {
        const data = await billingProductService.updateBillingProduct(billingProduct);
        return data;
    };
    const { mutate: updateBillingProduct, isLoading: isLoadingUpdate } = useMutation(updateBillingProductCall, {
        onSuccess: (res) => {
            onCancel();
            cleanCheckboxs();
            refreshList();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.recordUpdatedSuccess')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const validateForm = () => {
        return identificador && article && unidOp && studyClass && modality && modalityType && ticket && turno;
    };

    return (
        <>
            <Modal open={isOpen} onClose={onCancel} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 550,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 4
                    }}
                >
                    <Box className="content_sale_tool">
                        <Typography className="module_title" data-testid="module-title" gutterBottom>
                            {!articleEdit ? 'Crear Articulo' : 'Editar Articulo'}
                        </Typography>
                    </Box>
                    <Typography>
                        <div className="filter__line filter__line-2">
                            <FormControl size="small" className="form__control">
                                <TextField
                                    label="Identificador"
                                    id="outlined-size-small"
                                    size="small"
                                    type="text"
                                    value={identificador || ''}
                                    onChange={(ev: any) => setidentificador(ev.target.value.replace(/\s/g, ''))}
                                />
                            </FormControl>
                            <FormControl size="small" className="form__control">
                                <TextField
                                    label="Artículo"
                                    id="outlined-size-small"
                                    size="small"
                                    type="text"
                                    value={article || ''}
                                    onChange={(ev: any) => setarticle(ev.target.value.replace(/\s/g, ''))}
                                />
                            </FormControl>
                        </div>
                        <div className="filter__line filter__line-2 mt-1">
                            <FormControl size="small" className="form__control formCourse__matAutocomplete">
                                <Autocomplete
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={unidOp}
                                    options={unidOpData || []}
                                    onChange={(e: any, newValue: any | null) => setUnidOp(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={t('formFilter.unidOp')} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                            <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-studyClass">
                                <Autocomplete
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={studyClass}
                                    options={studyClassData || []}
                                    onChange={(e: any, newValue: any | null) => setStudyClass(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={t('formFilter.modality')} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className="filter__line filter__line-3__custom mt-1">
                            <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Modality">
                                <Autocomplete
                                    disabled={isDisabled}
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={modality}
                                    options={modalitiesData || []}
                                    onChange={(e: any, newValue: any | null) => setModality(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={t('formFilter.submodality')} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                            <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Ticket">
                                <Autocomplete
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={ticket}
                                    options={ticketsData || []}
                                    onChange={(e: any, newValue: any | null) => setTicket(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={t('formFilter.ticket')} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                            <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Cursando">
                                <Autocomplete
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={turno}
                                    options={turnosData || []}
                                    onChange={(e: any, newValue: any | null) => setTurno(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => `${option?.id} - ${option?.description}`}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={`${t('formFilter.cursando')}`} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.id} - {option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                        </div>
                    </Typography>
                    <div className="mt-1">
                        <Stack className="edit_Display_Button" direction="row" spacing={2}>
                            <Button color="error" variant="contained" onClick={onCancel}>
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={isLoadingCreate || isLoadingUpdate || !validateForm()}
                                startIcon={isLoadingCreate || isLoadingUpdate ? <CircularProgress size={20} color="inherit" /> : null}
                                onClick={onSubmit}
                            >
                                Confirmar
                            </Button>
                        </Stack>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default AddBillingProduct;
