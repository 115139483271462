import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableMessage from '../../../../shared/tableMessage/TableMessage';
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import { RowTableExchangeTicketsGraduation } from './row-table-exchange-tickets-graduation/row-table-exchange-tickets-graduation';
import { Edit as EditIcon } from '@mui/icons-material';
interface tableExchangePriceInterface {
    graduationData: any;
    isLoading: boolean;
    isFetching: boolean;
    handleEditTicket: Function;
    isRoleCargaPrecio: boolean;
    handleEditAllGraduation: Function;
}

export const TableExchangeTicketsGraduation = ({
    graduationData,
    isLoading,
    isFetching,
    handleEditTicket,
    isRoleCargaPrecio,
    handleEditAllGraduation
}: tableExchangePriceInterface) => {
    const { t } = useTranslation();
    const [showNoResultsMessage, setShowNoResultsMessage] = useState<boolean>(false);

    useEffect(() => {
        setShowNoResultsMessage(false);
        const timer = setTimeout(() => {
            if (!isFetching && !isLoading) {
                setShowNoResultsMessage(true);
            }
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [isFetching, isLoading]);

    if (isFetching || isLoading || graduationData?.length === 0) {
        if (!showNoResultsMessage) {
            return (
                <div className="flex-justify-content_center">
                    <CircularProgress id="rmt-loadingPrograms" color="success" />
                </div>
            );
        }
        if (graduationData?.length === 0) return <TableMessage msg={t('messages.resultsNotFount')} />;
    }

    return (
        <TableContainer className="table__price_list" component={Paper}>
            <Table>
                <TableBody className="table__body">
                    <TableRow className="table__exchange_tickets_graduation_Header">
                        <TableCell className="table__courseProgramCell">{'Clase de Estudio'}</TableCell>
                        <TableCell className="table__courseProgramCell">{'ID Centro de Costos'}</TableCell>
                        <TableCell className="table__courseProgramCell">{'Articulo'}</TableCell>
                        <TableCell className="table__courseProgramCell">{t('tableCell.price')}</TableCell>
                        <TableCell className="table__courseProgramCell">{t('tableCell.date')}</TableCell>
                        <TableCell className="table__courseProgramCell">{t('tableCell.user')}</TableCell>
                        <TableCell className="table__courseProgramCell">
                            <Tooltip title="Editar Todas">
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    id="rmt-syllabusEdit"
                                    onClick={() => handleEditAllGraduation()}
                                    disabled={!isRoleCargaPrecio}
                                    color="inherit"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    {graduationData?.map((item: any, i: number) => (
                        <RowTableExchangeTicketsGraduation
                            key={i}
                            item={item}
                            handleEditTicket={handleEditTicket}
                            isRoleCargaPrecio={isRoleCargaPrecio}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
