import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './current-prices.scss';
import Toolbar from '../../layout/Toolbar/Toolbar';
import { currentPricesService } from '../../../services/current-prices.service';
import { useQuery } from 'react-query';
import TableCurrentPrices from './table-current-prices/table-current-prices';
import FilterCurrentPrices from './filter-current-prices/filter-current-prices';
import { Box, Typography, LinearProgress } from '@mui/material';
import { STATUS_ARTICLE } from '../../../utils/constants';

const CurrentPrices = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<any>({});
    const [articles, setArticles] = useState<any[]>([]);
    const [articlesSelecteds, setArticlesSelecteds] = useState<any[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [isPartialCheck, setPartialCheck] = useState(false);
    const [isCheckedAll, setCheckedAll] = useState(false);
    const [isLoadingDownload, setLoadingDownload] = useState(false);
    const [page, setPage] = useState(0);
    const pageSize = 10;
    const [cancelRequest, setCancelRequest] = useState(false);

    const {
        data: articlesData,
        isLoading: isLoadingArticles,
        isFetching
    } = useQuery(
        ['articles', page, pageSize, filters],
        async () => {
            const data = await currentPricesService.getArticlesCurrentPrices(page, pageSize, filters);
            return data;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: !cancelRequest,
            onError: () => {
                setCancelRequest(true);
            },
            retry: false,
            cacheTime: 0
        }
    );

    useEffect(() => {
        return () => {
            setCancelRequest(true);
        };
    }, []);

    useEffect(() => {
        if (articlesData) {
            if (isCheckedAll) {
                const newList = articlesData?.articles.map((item: any) => ({ ...item, checked: true }));
                const selectedsIds = articlesSelecteds.map((item: any) => item.idArticle);
                const selecteds = [...newList];
                const result: any[] = [];
                selecteds.forEach((item) => {
                    if (!selectedsIds.includes(item.idArticle)) {
                        result.push(item);
                    }
                });
                const checkedsArray = [...articlesSelecteds, ...result];
                const list = selecteds.map((item: any) => {
                    const result = checkedsArray.find((elem) => elem.idArticle === item.idArticle);
                    if (result) {
                        return { ...item, checked: result.checked };
                    }
                    return { ...item, checked: false };
                });
                setArticlesSelecteds(checkedsArray);
                setArticles(list);
                const atLeastChecked = list.some((item: any) => item?.checked);
                setPartialCheck(atLeastChecked);
            } else {
                const articles = articlesData?.articles.map((item: any) => {
                    const result = articlesSelecteds.find((elem) => elem.idArticle === item.idArticle);
                    if (result) {
                        return { ...item, checked: result.checked };
                    }
                    return { ...item, checked: false };
                });
                setArticles(articles);
                const atLeastChecked = articles.some((item: any) => item?.checked);
                setPartialCheck(atLeastChecked);
            }
            setTotalElements(articlesData?.totalElements);
        }
    }, [articlesData]);

    const handleFilters = (filters: any) => {
        setFilters(filters);
        setPage(0);
    };

    const handlePage = (page: number) => {
        setPage(page);
    };

    const handleItemSelected = (e: any, item: any) => {
        const checked = e.target.checked;
        const newList = articles.map((article) => {
            if (article.idArticle === item.idArticle) {
                return { ...article, checked };
            }
            return { ...article };
        });
        if (!checked) {
            const index = articlesSelecteds.findIndex((article) => article.idArticle === item.idArticle);
            articlesSelecteds[index].checked = false;
            setArticlesSelecteds([...articlesSelecteds]);
        } else {
            const selectedsIds = articlesSelecteds.map((item: any) => item.idArticle);
            if (!selectedsIds.includes(item.idArticle)) {
                setArticlesSelecteds([...articlesSelecteds, { ...item, checked: true }]);
            } else {
                const index = articlesSelecteds.findIndex((article) => article.idArticle === item.idArticle);
                articlesSelecteds[index].checked = true;
                setArticlesSelecteds([...articlesSelecteds]);
            }
        }
        const atLeastChecked = newList.some((item) => item?.checked);
        setPartialCheck(atLeastChecked);
        setArticles(newList);
    };

    const handleCheckedAll = (e: any) => {
        const checked = e.target.checked;
        const newList = articles.map((item: any) => ({ ...item, checked }));
        setPartialCheck(false);
        if (!checked) {
            setArticlesSelecteds([]);
        } else {
            setArticlesSelecteds([...newList]);
        }
        setCheckedAll(checked);
        setArticles(newList);
    };

    const handleDownloadCsvAPI = async () => {
        const articlesArray = articlesSelecteds.filter((art: any) => !art.checked);
        const selectedsIds = articlesArray.map((item: any) => item.idArticle);
        setLoadingDownload(true);
        try {
            const response = await currentPricesService.downloadCsvPriceList(STATUS_ARTICLE.APPROVED, selectedsIds, filters);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'lista_precios.csv');
            document.body.appendChild(link);
            link.click();
            setLoadingDownload(false);
        } catch (error) {
            setLoadingDownload(false);
        }
    };

    return (
        <>
            <div className="layout__toolbar">
                <Toolbar />
            </div>
            <div className="container">
                <Box className="content_current_prices">
                    <Typography className="module_title" data-testid="module-title" gutterBottom>
                        {t('titles.currenPrice')}
                    </Typography>
                    <Typography className="module_subtitle1">{t('subtitles.subtitlePrices')}</Typography>
                </Box>
                <div className="create__controls">
                    <div className="create__line">
                        <FilterCurrentPrices handleFilters={handleFilters} />
                    </div>
                </div>
                {isLoadingDownload && (
                    <>
                        <h4 className="module_title">Descargando CSV...</h4>
                        <LinearProgress color="success" />
                    </>
                )}
                {!isLoadingDownload && (
                    <div className="mt-2">
                        <TableCurrentPrices
                            articles={articles}
                            totalElements={totalElements}
                            isLoading={isLoadingArticles}
                            isFetching={isFetching}
                            page={page}
                            handlePage={handlePage}
                            pageSize={pageSize}
                            handleItemSelected={handleItemSelected}
                            partialCheck={isPartialCheck}
                            isCheckedAll={isCheckedAll}
                            handleCheckedAll={handleCheckedAll}
                            articlesSelecteds={articlesSelecteds}
                            handleDownloadCsvAPI={handleDownloadCsvAPI}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CurrentPrices;
