import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableMessage from '../../../../shared/tableMessage/TableMessage';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { RowTableExchangeTicketsPrice } from './row-table-exchange-tickets-price/row-table-exchange-tickets-price';

interface tableExchangePriceInterface {
    modalityData: any; //! actualizar por tickets hisotrical cuando esté el filtro
    isLoadingM: boolean;
    isFetchingM: boolean;
    handleEditTicket: Function;
    isRoleCargaPrecio: boolean;
}

export const TableExchangeTicketsPrice = ({
    modalityData,
    isLoadingM,
    isFetchingM,
    handleEditTicket,
    isRoleCargaPrecio
}: tableExchangePriceInterface) => {
    const { t } = useTranslation();
    const [showNoResultsMessage, setShowNoResultsMessage] = useState<boolean>(false);

    useEffect(() => {
        setShowNoResultsMessage(false);
        const timer = setTimeout(() => {
            if (!isLoadingM && !isFetchingM) {
                setShowNoResultsMessage(true);
            }
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [isLoadingM, isFetchingM]);

    if (isLoadingM || isFetchingM || modalityData?.length === 0) {
        if (!showNoResultsMessage) {
            return (
                <div className="flex-justify-content_center">
                    <CircularProgress id="rmt-loadingPrograms" color="success" />
                </div>
            );
        }
        if (modalityData?.length === 0) return <TableMessage msg={t('messages.resultsNotFount')} />;
    }

    return (
        <TableContainer className="table__price_list" component={Paper}>
            <Table>
                <TableBody className="table__body">
                    <TableRow className="table__exchange_tickets_price_Header">
                        <TableCell className="table__courseProgramCell">{t('tableCell.numberSubjects')}</TableCell>
                        <TableCell className="table__courseProgramCell">{t('tableCell.price')}</TableCell>
                        <TableCell className="table__courseProgramCell">{t('tableCell.date')}</TableCell>
                        <TableCell className="table__courseProgramCell">{t('tableCell.user')}</TableCell>
                        <TableCell className="table__courseProgramCell"></TableCell>
                    </TableRow>
                    {modalityData?.map((item: any, i: number) => (
                        <RowTableExchangeTicketsPrice key={i} item={item} handleEditTicket={handleEditTicket} isRoleCargaPrecio={isRoleCargaPrecio} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
