import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FilterExchangeTicketsPrice } from './filter-exchange-tickets-price/filter-exchange-tickets-Price';
import { TableExchangeTicketsPrice } from './table-exchange-tickets-price/table-exchange-tickets-price';
import { exchangeTicketsService } from '../../../../services/exchange-tickets.service';
import { useQuery } from 'react-query';
import { ModalEditPrice } from './modal/modal-edit-price';
import { hasRole } from '../../../../utils/utils';
import { AUTHORITIES } from '../../../../utils/constants';
import { AppContext } from '../../../../App.context';

export const PriceExchangeTickets = () => {
    const appContext = useContext(AppContext);
    const user = appContext?.state.user;
    const [filters, setFilters] = useState<any>({});
    const [isTableOpen, setisTableOpen] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [priceTicketEdit, setPriceTicketEdit] = useState<any>({});
    const [modalityType, setModalityType] = useState<any>(null); // career
    const [isCheckedAllPrice, setisCheckedAllPrice] = useState<boolean>(false);
    const [isRoleCargaPrecio, setisRoleCargaPrecio] = useState(false);

    const {
        data: modalityData,
        isLoading: isLoadingM,
        isFetching: isFetchingM,
        refetch
    } = useQuery(
        ['modalitiET', filters],
        async () => {
            const data = await exchangeTicketsService.getByModalidadData(filters);
            return data;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        setisRoleCargaPrecio(hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS]));
    }, []);

    const handleFilters = (filters: any) => {
        setFilters(filters);
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    const handleEditTicket = (item: any) => {
        //! BTN EDIT
        setPriceTicketEdit(item);
        handleOpenModal();
    };

    return (
        <>
            <Box className="create__controls">
                <Box className="create__line">
                    <FilterExchangeTicketsPrice
                        handleFilters={handleFilters}
                        setisTableOpen={setisTableOpen}
                        modalityType={modalityType}
                        setModalityType={setModalityType}
                        setisCheckedAllPrice={setisCheckedAllPrice}
                        isCheckedAllPrice={isCheckedAllPrice}
                        isRoleCargaPrecio={isRoleCargaPrecio}
                    />
                </Box>
                {isTableOpen && (
                    <Box>
                        <TableExchangeTicketsPrice
                            modalityData={modalityData}
                            isLoadingM={isLoadingM}
                            isFetchingM={isFetchingM}
                            handleEditTicket={handleEditTicket}
                            isRoleCargaPrecio={isRoleCargaPrecio}
                        />
                    </Box>
                )}
            </Box>
            {isOpen && (
                <ModalEditPrice
                    isOpen={isOpen}
                    onCancel={closeModal}
                    setIsOpen={setIsOpen}
                    modalityType={modalityType}
                    priceTicketEdit={priceTicketEdit}
                    refetch={refetch}
                    isCheckedAllPrice={isCheckedAllPrice}
                    user={user}
                />
            )}
        </>
    );
};
