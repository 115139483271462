export const getBaseUrl = () => {
    return `${process.env.REACT_APP_PRICING_BFF_URL || ''}`;
};

export const hasRole = (user :any = {}, roles: any = []) => {
    const authorities = user && user.authorities ? user.authorities : [];
    return authorities.some((autor: any) => roles.includes(autor.authority));
};

export const errorMessages = (errotType: string) => {
    switch (errotType) {
        case 'maxDate':
            return {
                msg: 'La fecha debe ser menor al 31/12/2050',
                valid: false
            };
        case 'minDate':
            return {
                msg: 'La fecha debe ser mayor al 01/01/2000',
                valid: false
            };
        case 'invalidDate':
            return {
                msg: 'Fecha Inválida',
                valid: false
            };
        case 'startGreaterThanEnd':
            return {
                msg: 'La fecha inicial no puede ser mayor a la fecha final',
                valid: false
            };
        case 'endLessThanStart':
            return {
                msg: 'La fecha final no puede ser menor a la fecha inicial',
                valid: false
            };
        default:
            return {
                msg: '',
                valid: true
            };
    }
};
