import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Chip, Table, TableBody, TableCell, TableRow, Collapse, Tooltip } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import '../table-log-display.scss';
import EditLogDisplay from '../../edit-log-display/edit-log-display';
import { STATUS_ARTICLE } from '../../../../../../utils/constants';
import { formatDate } from '../../../../../../utils/formatDate';
import { formatPrice } from '../../../../../../utils/formatPrice';

const RowTableLogDisplay = ({ item, handleItemSelected, handleCleanFilter }: any) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleEdit = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        handleCleanFilter();
        setIsOpen(false);
    };

    /* const editClose = () => {
        handleCleanFilter();
        setIsOpen(false);
    }; */

    const color = item.idEstado === STATUS_ARTICLE.DRAFT ? 'warning' : item.idEstado === STATUS_ARTICLE.REFUSED ? 'error' : 'default';

    return (
        <>
            <TableRow sx={{ boxShadow: 2 }} className="table__mainRow">
                <TableCell className="table__cellAux">
                    <Table>
                        <TableBody>
                            <TableRow className="table__log_Display_Body" hover={true} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell className="table__cell table__controlCell">
                                    <Checkbox
                                        checked={item?.checked}
                                        disabled={item?.idEstado !== STATUS_ARTICLE.DRAFT}
                                        onChange={(e) => handleItemSelected(e, item)}
                                    />
                                </TableCell>
                                <TableCell className="table__courseProgramCellBody">{item.csvId}</TableCell>
                                <TableCell className="table__courseProgramCellBody">
                                    {item.idCarrera} - {item.descripcionCarrera}
                                </TableCell>
                                <TableCell className="table__courseProgramCellBody">{item.cau}</TableCell>
                                <TableCell className="table__courseProgramCellBody">{item.tipoModalidad}</TableCell>
                                <TableCell className="table__courseProgramCellBody">{item.periodoAcademico}</TableCell>
                                <TableCell className="table__courseProgramCellBody">{item.typeTicket}</TableCell>
                                <TableCell className="table__courseProgramCellBody">{item.turnoCursado} - {item.turnoCursadoDesc}</TableCell>
                                <TableCell className="table__courseProgramCellBody">
                                    <Chip label={item.estado} color={color} />
                                </TableCell>
                                <TableCell className="table__courseProgramCellBody">{item.tipoALumno}</TableCell>
                                <TableCell className="table__courseProgramCellBody">{formatPrice(item.precio)}</TableCell>
                                <TableCell className="table__cell table__controlCell">
                                    <Tooltip style={{ paddingInline: '5px' }} title={t('tooltips.editLog')}>
                                        <Box>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                id="rmt-syllabusEdit"
                                                disabled={item.idEstado !== STATUS_ARTICLE.DRAFT}
                                                onClick={handleEdit}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className="table__cell table__controlCell">
                                    {isAccordionOpen ? (
                                        <IconButton
                                            aria-label="rmt-courseExpand"
                                            size="small"
                                            id="rmt-courseExpand"
                                            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                                        >
                                            <KeyboardArrowUpIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="rmt-courseExpand"
                                            size="small"
                                            id="rmt-courseExpand"
                                            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                                        >
                                            <KeyboardArrowDownIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ width: '100%', display: 'grid' }}>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={6}>
                                    <Collapse in={isAccordionOpen} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: 0 }}>
                                            <Table size="small" sx={{ borderSpacing: '0px 0px !important' }}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="accordion">
                                                            <Box className="accordion_content">
                                                                <Box className="accordion_item">
                                                                    <p className="title-detail-classroom">{t('tableCell.registrationData')}</p>
                                                                    <Box className="row">
                                                                        <Box className="col col-25">
                                                                            <p className="subtitle-detail-1">{t('tableCell.dateI')}</p>
                                                                            <Box className="mt-1">{formatDate(item.fechaInicio)}</Box>
                                                                        </Box>
                                                                        <Box className="col col-25">
                                                                            <p className="subtitle-detail-1">{t('tableCell.idArticle')}</p>
                                                                            <Box className="mt-1">{item.idArticle}</Box>
                                                                        </Box>
                                                                        <Box className="col col-25">
                                                                            <p className="subtitle-detail-1">{t('tableCell.modalityDes')}</p>
                                                                            <Box className="mt-1">{item.modality}</Box>
                                                                        </Box>
                                                                        <Box className="col col-25">
                                                                            <p className="subtitle-detail-1">{t('tableCell.cboi')}</p>
                                                                            <Box className="mt-1">{item.cboi}</Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="row">
                                                                        <Box className="col col-25">
                                                                            <p className="subtitle-detail-1">{t('tableCell.percentage')}</p>
                                                                            <Box className="mt-1">{item.porcentaje}</Box>
                                                                        </Box>
                                                                        <Box className="col col-25">
                                                                            <p className="subtitle-detail-1">{t('tableCell.numberSubjects')}</p>
                                                                            <Box className="mt-1">{item.cantidadMaterias}</Box>
                                                                        </Box>
                                                                        <Box className="col col-25">
                                                                            <p className="subtitle-detail-1">{t('tableCell.tariff')}</p>
                                                                            <Box className="mt-1">{item.tipoArancel}</Box>
                                                                        </Box>
                                                                        <Box className="col col-25"></Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
            <EditLogDisplay isOpen={isOpen} onCancel={closeModal} item={item} />
        </>
    );
};

export default RowTableLogDisplay;
