import { Box, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { exchangeTicketsService } from '../../../../services/exchange-tickets.service';
import { useQuery } from 'react-query';
import { ModalEditPrice } from './modal-edit-graduation/modal-edit-graduation';
import { hasRole } from '../../../../utils/utils';
import { AUTHORITIES } from '../../../../utils/constants';
import { AppContext } from '../../../../App.context';
import { TableExchangeTicketsGraduation } from './table-exchange-tickets-graduation/table-exchange-tickets-graduation';
import FormGraduationExchangeTickets from './form-graduation-exchange-tickets/form-graduation-exchange-tickets';
import PostAddIcon from '@mui/icons-material/PostAdd';

export const GradiatuionExchangeTickets = () => {
    const appContext = useContext(AppContext);
    const user = appContext?.state.user;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [priceTicketEdit, setPriceTicketEdit] = useState<any>({});
    const [isAllGraduationSelected, setIsAllGraduationSelected] = useState<boolean>(false);
    const [isRoleCargaPrecio, setisRoleCargaPrecio] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

    const {
        data: graduationData,
        isLoading,
        isFetching,
        refetch
    } = useQuery('graduationDataET', exchangeTicketsService.getPriceGraduacion, {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        setisRoleCargaPrecio(hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS]));
    }, []);

    const handleOpenModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    const handleEditTicket = (item: any) => {
        setPriceTicketEdit(item);
        setIsAllGraduationSelected(false);
        handleOpenModal();
    };
    const handleEditAllGraduation = () => {
        setIsAllGraduationSelected(true);
        handleOpenModal();
    };

    const handleOpenFormModal = () => {
        setIsFormOpen(true);
    };

    const handleCloseFormModal = () => {
        setIsFormOpen(false);
    };

    return (
        <>
            <Box className="create__controls">
                {!isLoading && (
                    <Box>
                        <Button
                            variant="contained"
                            component="label"
                            onClick={handleOpenFormModal}
                            startIcon={<PostAddIcon />}
                            disabled={!isRoleCargaPrecio}
                        >
                            {'NUEVO TICKET'}
                        </Button>
                        <FormGraduationExchangeTickets open={isFormOpen} onClose={handleCloseFormModal} refetch={refetch} user={user} />
                        <TableExchangeTicketsGraduation
                            graduationData={graduationData}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            handleEditTicket={handleEditTicket}
                            isRoleCargaPrecio={isRoleCargaPrecio}
                            handleEditAllGraduation={handleEditAllGraduation}
                        />
                    </Box>
                )}
            </Box>
            {isOpen && (
                <ModalEditPrice
                    isOpen={isOpen}
                    onCancel={closeModal}
                    setIsOpen={setIsOpen}
                    graduationData={graduationData}
                    priceTicketEdit={priceTicketEdit}
                    refetch={refetch}
                    isAllGraduationSelected={isAllGraduationSelected}
                    user={user}
                />
            )}
        </>
    );
};
