import React, { MouseEvent, useRef } from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { RowBillingPproduct } from './row-billing-product/row-billing-product';
import TablePaginationActions from '../../../shared/pagination/TablePaginationActions';
import { useTranslation } from 'react-i18next';
import TableMessage from '../../../shared/tableMessage/TableMessage';
import { Download } from '@mui/icons-material';
import { CSVLink } from 'react-csv';

type billingProductT = {
    listCarrers: any;
    totalElements: number;
    pageSize: number;
    page: number;
    handlePage: Function;
    isLoading: any;
    isFetching: any;
    isCheckedAll: any;
    isPartialCheck: any;
    handleItemSelected: any;
    handleCheckedAll: any;
    selecteds: any;
    handleEditArticle: Function;
    handleDeleteArticle: Function;
    isRoleCargaPrecio: boolean;
};

export const TableBillingProduct = ({
    listCarrers,
    totalElements,
    pageSize,
    page,
    handlePage,
    isLoading,
    isFetching,
    isCheckedAll,
    isPartialCheck,
    handleItemSelected,
    handleCheckedAll,
    selecteds,
    handleEditArticle,
    handleDeleteArticle,
    isRoleCargaPrecio
}: billingProductT) => {
    const { t } = useTranslation();
    const refs = useRef<any | null>(null);

    const headers = [
        { label: 'IDENTIFICADOR', key: 'id' },
        { label: 'ARTICULO', key: 'articulo' },
        { label: 'TIPO_MODALIDAD', key: 'descripcionTipoModalidad' },
        { label: 'TICKET', key: 'tipoTicket' },
        { label: 'CARRERA', key: 'descripcionCarrera' },
        { label: 'TURNO_CURSADO', key: 'turnoCursadoDescripcion' },
        { label: 'UNIDAD_OPERATIVA', key: 'uniOp' },
        { label: 'PRECIO', key: 'precio' }
    ];

    const handleDownloadCSV = () => {
        refs.current.link.click();
    };

    const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, currenPage: number) => {
        handlePage(currenPage);
    };

    if (isLoading || isFetching) {
        return (
            <div className="flex-justify-content_center">
                <CircularProgress id="rmt-loadingPrograms" color="success" />
            </div>
        );
    }

    if (totalElements === 0) return <TableMessage msg={t('messages.resultsNotFount')} />;

    return (
        <>
            {(selecteds.some((item: any) => item?.checked) || isCheckedAll) && (
                <>
                    <Button variant="contained" type="button" className="ml-1" startIcon={<Download />} onClick={handleDownloadCSV}>
                        {t('buttons.download')}
                    </Button>
                    <CSVLink
                        data={selecteds.filter((item: any) => item.checked)}
                        headers={headers}
                        filename={'lista_producto_facturacion.csv'}
                        ref={refs}
                        separator={';'}
                        enclosingCharacter={''}
                        uFEFF={false}
                    />{' '}
                    <div className="mt-1">
                        <small>
                            <b>{selecteds.filter((item: any) => item.checked).length} registros seleccionados</b>
                        </small>
                    </div>
                </>
            )}
            <TableContainer className="table__price_list" component={Paper}>
                <Table>
                    <TableBody className="table__body">
                        <TableRow className="table__billing_product_Header">
                            <TableCell className="table__cell table__controlCell">
                                <Checkbox
                                    color="default"
                                    checked={isCheckedAll}
                                    // indeterminate={isPartialCheck}
                                    indeterminate={isPartialCheck}
                                    onChange={(e) => handleCheckedAll(e)}
                                />
                            </TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.identifier')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.Article')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.modality')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.ticket')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.career')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.Shift')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.unidOp')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.price')}</TableCell>
                        </TableRow>
                        {listCarrers?.map((item: any, i: number) => (
                            <RowBillingPproduct
                                key={i}
                                item={item}
                                handleItemSelected={handleItemSelected}
                                handleEditArticle={handleEditArticle}
                                handleDeleteArticle={handleDeleteArticle}
                                isRoleCargaPrecio={isRoleCargaPrecio}
                            />
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={totalElements || 0}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`;
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};
