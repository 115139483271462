import React, { useState } from 'react';
import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { saleToolServicies } from '../../../../services/sale-tool.service';
import { Search } from '@mui/icons-material';

interface FilterI {
    handleFilters: any;
}

const FilterSaleTool = ({ handleFilters }: FilterI) => {
    const { t } = useTranslation();
    const [cod, setCod] = useState<any>(null);
    const [studyClass, setStudyClass] = useState<any>(null);
    const [studentType, setStudentType] = useState<any>(null);
    const studentTypesOptions = [
        { value: '', label: t('labels.all') },
        { value: 'I', label: t('formFilter.studentEntrance') },
        { value: 'R', label: t('formFilter.studentReEntering2') }
    ];

    const { data: studyClassData } = useQuery(
        'studyClass',
        async () => {
            const data = await saleToolServicies.getStudyClass();
            return data?.map((item: any) => ({ id: item.id, description: `${item.id} - ${item.description}` }));
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const { data: codData } = useQuery(
        'codPeriod',
        async () => {
            const data = await saleToolServicies.getCodPeriods();
            return data;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const handleClean = () => {
        setCod(null);
        setStudyClass(null);
        setStudentType(null);
        handleFilters({
            code: null,
            studyClass: null,
            studentTypeId: null
        });
    };

    const handleSubmit = () => {
        handleFilters({
            code: cod?.id,
            studyClass: studyClass?.id,
            studentTypeId: studentType?.value
        });
    };

    return (
        <form className="filter__prices">
            <div className="filter__controls">
                <div className="filter__line filter__line-3__custom">
                    <FormControl size="small" className="form__control formCourse__matAutocomplete">
                        <Autocomplete
                            clearText={'Limpiar'}
                            openText=""
                            value={cod}
                            options={codData || []}
                            onChange={(e: any, newValue: any | null) => setCod(newValue)}
                            disablePortal
                            noOptionsText={t('labels.noOptionsText')}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option?.description}
                            renderInput={(params) => <TextField key={params.id} value={params.id} {...params} label={t('formFilter.period')} />}
                            renderOption={(props, option: any) => (
                                <li {...props} value={option?.id} key={option?.id}>
                                    {option?.description}
                                </li>
                            )}
                        />
                    </FormControl>
                    <FormControl size="small" className="form__control formCourse__matAutocomplete">
                        <Autocomplete
                            clearText={'Limpiar'}
                            openText=""
                            value={studyClass}
                            options={studyClassData || []}
                            onChange={(e: any, newValue: any | null) => setStudyClass(newValue)}
                            disablePortal
                            noOptionsText={t('labels.noOptionsText')}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option?.description}
                            renderInput={(params) => <TextField key={params.id} value={params.id} {...params} label={t('formFilter.studyClass')} />}
                            renderOption={(props, option: any) => (
                                <li {...props} value={option?.id} key={option?.id}>
                                    {option?.description}
                                </li>
                            )}
                        />
                    </FormControl>
                    <FormControl size="small" className="form__control formCourse__matAutocomplete">
                        <Autocomplete
                            clearText={'Limpiar'}
                            openText=""
                            value={studentType}
                            options={studentTypesOptions || []}
                            onChange={(e: any, newValue: any | null) => setStudentType(newValue)}
                            disablePortal
                            noOptionsText={t('labels.noOptionsText')}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            getOptionLabel={(option) => option?.label.toString()}
                            renderInput={(params) => <TextField {...params} label={t('formFilter.typeStudent')} />}
                            renderOption={(props, option: any) => (
                                <li {...props} value={option?.value} key={option?.value}>
                                    {option?.label}
                                </li>
                            )}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="filter__buttons">
                <Button variant="contained" type="button" onClick={handleSubmit} startIcon={<Search />}>
                    {t('buttons.search')}
                </Button>
                <Button type="button" className="button-clean-filter" onClick={handleClean}>
                    LIMPIAR FILTROS
                </Button>
            </div>
        </form>
    );
};

export default FilterSaleTool;
