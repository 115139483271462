import { ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            light: '#08c5ab',
            main: '#006c5b',
            dark: '#014e44',
            contrastText: '#FFF'
        },
        secondary: {
            light: '#08c5ab',
            main: '#08c5ab',
            dark: '#08c5ab',
            contrastText: '#FFF'
        },
        error: {
            main: '#dc1351',
            contrastText: '#FFF'
        },
        warning: {
            main: '#faa41a',
            contrastText: '#FFF'
        },
        info: {
            main: '#66a69c',
            contrastText: '#FFF'
        },
        success: {
            main: '#00a98f',
            contrastText: '#FFF'
        }
    },
    typography: {
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        body1: {
            fontWeight: 500
        }
    }
};
