import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Search as SearchIcon } from '@mui/icons-material';
import { Autocomplete, Box, Button, FormControl, TextField } from '@mui/material';
import { currentPricesService } from '../../../../../services/current-prices.service';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { errorMessages } from '../../../../../utils/utils';
import { exchangeTicketsService } from '../../../../../services/exchange-tickets.service';

interface FilterInterface {
    handleFilters: any;
    isFetching: boolean;
}

const quantityMaterialsData = [
    { id: 1, description: '1' },
    { id: 2, description: '2' },
    { id: 3, description: '3' },
    { id: 4, description: '4' },
    { id: 5, description: '5' },
    { id: 6, description: '6' }
];

const typeStateData = [
    // Cambiar id por value
    { id: 'S', description: 'Activo' },
    { id: 'N', description: 'Inactivo' }
];
const tariffTypeData = [
    { id: '1', description: 'Intercambio' },
    { id: '30', description: 'Graduación' }
];

export const FilterExchangeTicketsHistorical = ({ handleFilters, isFetching }: FilterInterface) => {
    const { t } = useTranslation();
    const [modality, setModality] = useState<any>(null);
    const [studyClass, setStudyClass] = useState<any>(null);
    const [quantityMaterials, setQuantityMaterials] = useState<any>(null);
    const [typeState, setTypeState] = useState<any>(null);
    const [tariffType, setTariffType] = useState<any>(tariffTypeData[0]);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [errorStartDate, setErrorStartDate] = useState<any>(null);
    const [errorEndDate, setErrorEndDate] = useState<any>(null);
    const [errorMessageStartDate, setErrorMessageStartDate] = useState('');
    const [errorMessageEndDate, setErrorMessageEndDate] = useState('');
    const [valid, setValid] = useState<boolean>(true);

    useEffect(() => {
        const { msg, valid } = errorMessages(errorStartDate);
        setErrorMessageStartDate(msg);
        setValid(valid);
    }, [errorStartDate]);

    useEffect(() => {
        const { msg, valid } = errorMessages(errorEndDate);
        setErrorMessageEndDate(msg);
        setValid(valid);
    }, [errorEndDate]);

    const { data: modalitiesData } = useQuery('modalitiesET', currentPricesService.getModalities, {
        refetchOnWindowFocus: false
    });

    const { data: studyClassData } = useQuery('allStudyClassesET', exchangeTicketsService.getAllStudyClasses, {
        refetchOnWindowFocus: false
    });

    // Botón Submit
    const handleSubmit = () => {
        handleFilters({
            modalidad: modality?.id,
            studyClass: studyClass?.id,
            cantMaterias: quantityMaterials?.id,
            estado: typeState?.id,
            startDate: startDate && new Date(startDate.format('YYYY-MM-DD')).toISOString().slice(0, 10),
            endDate: endDate && new Date(endDate.format('YYYY-MM-DD')).toISOString().slice(0, 10),
            tipoArancelId: tariffType?.id
        });
    };

    const handleClean = () => {
        setModality(null);
        setStudyClass(null);
        setQuantityMaterials(null);
        setTypeState(null);
        setTariffType(tariffTypeData[0]);
        setStartDate(null);
        setEndDate(null);
        handleFilters({
            modalidad: null,
            studyClass: null,
            cantMaterias: null,
            estado: null,
            startDate: null,
            endDate: null,
            tipoArancelId: tariffTypeData[0]?.id
        });
    };

    // onChange Fecha
    const handleChangeStartDate = (startDate: Moment) => {
        if (startDate?.isValid() && endDate) {
            if (startDate.isAfter(endDate)) {
                setErrorStartDate('startGreaterThanEnd');
            } else {
                setErrorStartDate('');
                setErrorEndDate('');
            }
        }
        setStartDate(startDate);
    };

    const handleChangeEndDate = (endDate: Moment) => {
        if (endDate?.isValid() && startDate) {
            if (endDate.isBefore(startDate)) {
                setErrorEndDate('endLessThanStart');
            } else {
                setErrorStartDate('');
                setErrorEndDate('');
            }
        }
        setEndDate(endDate);
    };

    return (
        <form className="form_container_historical_tickets">
            <div className="filter__prices">
                <div className="filter__control">
                    <Box className="filter__line filter__body_exchange_tickets_line_historical_1">
                        <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Tariff-Type">
                            <Autocomplete
                                clearText={'Limpiar'}
                                openText=""
                                value={tariffType}
                                options={tariffTypeData || []}
                                onChange={(e: any, newValue: any) => {
                                    if (newValue) {
                                        setTariffType(newValue);
                                        setModality(null);
                                        setStudyClass(null);
                                        setQuantityMaterials(null);
                                        setTypeState(null);
                                        setStartDate(null);
                                        setEndDate(null);
                                        handleFilters({
                                            modalidad: null,
                                            studyClass: null,
                                            cantMaterias: null,
                                            estado: null,
                                            startDate: startDate && new Date(startDate.format('YYYY-MM-DD')).toISOString().slice(0, 10),
                                            endDate: endDate && new Date(endDate.format('YYYY-MM-DD')).toISOString().slice(0, 10),
                                            tipoArancelId: newValue?.id
                                        });
                                    }
                                }}
                                disablePortal
                                noOptionsText={t('labels.noOptionsText')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => `${option?.description}`}
                                renderInput={(params) => (
                                    <TextField required key={params.id} value={params.id} {...params} label={`${t('formFilter.tariffType')}`} />
                                )}
                                renderOption={(props, option: any) => (
                                    <li {...props} value={option?.id} key={option?.id}>
                                        {`${option?.description}`}
                                    </li>
                                )}
                            />
                        </FormControl>
                        {tariffType.description === 'Intercambio' && (
                            <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Modality">
                                <Autocomplete
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={modality}
                                    options={modalitiesData || []}
                                    onChange={(e: any, newValue: any | null) => setModality(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.id + ' - ' + option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={t('formFilter.modality')} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.id + ' - ' + option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                        )}
                        {tariffType.description === 'Graduación' && (
                            <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Modality">
                                <Autocomplete
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={studyClass}
                                    options={studyClassData || []}
                                    onChange={(e: any, newValue: any | null) => setStudyClass(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.id + ' - ' + option?.description}
                                    renderInput={(params) => <TextField key={params.id} value={params.id} {...params} label={'Clase de Estudio'} />}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.id + ' - ' + option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                        )}
                        <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Type-State">
                            <Autocomplete
                                clearText={'Limpiar'}
                                openText=""
                                value={typeState}
                                options={typeStateData || []}
                                onChange={(e: any, newValue: any | null) => setTypeState(newValue)}
                                disablePortal
                                noOptionsText={t('labels.noOptionsText')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) =>
                                    // `${option?.id} - ${option?.description}`
                                    `${option?.description}`
                                }
                                renderInput={(params) => (
                                    <TextField key={params.id} value={params.id} {...params} label={`${t('formFilter.typeState')}`} />
                                )}
                                renderOption={(props, option: any) => (
                                    <li {...props} value={option?.id} key={option?.id}>
                                        {`${option?.description}`}
                                    </li>
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box className="filter__line filter__body_exchange_tickets_line_historical_2">
                        <FormControl size="small" className="form__control formCourse__matAutocomplete" id="rmt-Quantity-Materials">
                            <Autocomplete
                                clearText={'Limpiar'}
                                openText=""
                                value={quantityMaterials}
                                disabled={tariffType.description !== 'Intercambio'}
                                options={quantityMaterialsData || []}
                                onChange={(e: any, newValue: any | null) => setQuantityMaterials(newValue)}
                                disablePortal
                                noOptionsText={t('labels.noOptionsText')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option?.description}
                                renderInput={(params) => (
                                    <TextField key={params.id} value={params.id} {...params} label={t('formFilter.quantityMaterials')} />
                                )}
                                renderOption={(props, option: any) => (
                                    <li {...props} value={option?.id} key={option?.id}>
                                        {option?.description}
                                    </li>
                                )}
                            />
                        </FormControl>
                        <FormControl size="small" className="form__control" id="rmt-startDate">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DemoContainer components={['DatePicker']} sx={{ paddingBottom: 1 }}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                                    >
                                        <DatePicker
                                            className="form__control"
                                            format="DD/MM/YYYY"
                                            label="Fecha Desde"
                                            localeText={{ fieldYearPlaceholder: (params) => 'A'.repeat(params.digitAmount) }}
                                            slotProps={{
                                                textField: { size: 'small', helperText: errorMessageStartDate },
                                                actionBar: {
                                                    actions: ['clear']
                                                }
                                            }}
                                            onError={(newError) => setErrorStartDate(newError)}
                                            value={startDate}
                                            onChange={(newValue) => handleChangeStartDate(newValue)}
                                            minDate={moment('2000-01-01T00:00:00.000')}
                                            maxDate={moment('2050-12-31T23:59:59.999')}
                                        />
                                    </LocalizationProvider>
                                </DemoContainer>
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl size="small" className="form__control" id="rmt-endDate">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DemoContainer components={['DatePicker']} sx={{ paddingBottom: 1 }}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                                    >
                                        <DatePicker
                                            className="form__control"
                                            format="DD/MM/YYYY"
                                            label="Fecha Hasta"
                                            localeText={{ fieldYearPlaceholder: (params) => 'A'.repeat(params.digitAmount) }}
                                            slotProps={{
                                                textField: { size: 'small', helperText: errorMessageEndDate },
                                                actionBar: {
                                                    actions: ['clear']
                                                }
                                            }}
                                            onError={(newError) => setErrorEndDate(newError)}
                                            value={endDate}
                                            onChange={(newValue) => handleChangeEndDate(newValue)}
                                            minDate={moment('2000-01-01T00:00:00.000')}
                                            maxDate={moment('2050-12-31T23:59:59.999')}
                                        />
                                    </LocalizationProvider>
                                </DemoContainer>
                            </LocalizationProvider>
                        </FormControl>
                    </Box>
                </div>
                <div className="filter__buttons">
                    <Button
                        variant="contained"
                        type="button"
                        startIcon={<SearchIcon />}
                        onClick={handleSubmit}
                        disabled={!valid || isFetching}
                        id="rmt-submitButton"
                    >
                        {t('buttons.search')}
                    </Button>
                    <Button type="button" className="button-clean-filter" onClick={handleClean} id="rmt-cleanButton">
                        {t('buttons.cleanFilters')}
                    </Button>
                    <p className="required-field">(*) {t('labels.requiredField')}</p>
                </div>
            </div>
        </form>
    );
};
