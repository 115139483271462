import React, { useState, useEffect, useContext } from 'react';
import Modal from '@mui/material/Modal';
import {
    FormControl,
    Typography,
    Box,
    TextField,
    Autocomplete,
    Stack,
    Button,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { esES } from '@mui/x-date-pickers/locales';
import { saleToolServicies } from '../../../../services/sale-tool.service';
import { AppContext } from '../../../../App.context';
import { appReducerCases } from '../../../../reducers/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import '../sale-tool.scss';

const NewModal = ({ isOpen, onCancel, priceDate }: any) => {
    const { t } = useTranslation();
    const [date, setDate] = useState<any>(null);
    const [errorDate, setErrorDate] = useState<any>(null);
    const [errorMessageDate, setErrorMessageDate] = useState('');
    const [validDate, setValidDate] = useState<boolean>(true);
    const [cod, setCod] = useState<any>(null);
    const [studyClass, setStudyClass] = useState<any>(null);
    const [studentType, setStudentType] = useState<any>('I');
    const [disabledFields, setDisabledFields] = useState<boolean>(false);
    const appContext = useContext(AppContext);
    const queryClient = useQueryClient();
    const studentTypesOptions = [
        { value: 'I', label: t('formFilter.studentEntrance') },
        { value: 'R', label: t('formFilter.studentReEntering2') }
    ];

    const { data: codData } = useQuery('cod', saleToolServicies.getCodPeriods, {
        refetchOnWindowFocus: false
    });

    const { data: studyClassData } = useQuery(
        'studyClass',
        async () => {
            const data = await saleToolServicies.getStudyClass();
            return data?.map((item: any) => ({ id: item.id, description: `${item.id} - ${item.description}` }));
        },
        {
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        const { msg, valid } = errorMessages(errorDate);
        setErrorMessageDate(msg);
        setValidDate(valid);
    }, [errorDate]);

    useEffect(() => {
        if (priceDate) {
            setDisabledFields(true);
            setDate(moment(priceDate.priceDateList, 'YYYY-MM-DD'));
            setStudentType(priceDate.typeAlumn);
            setCod({ id: priceDate.period, description: priceDate.period || '' });
            setStudyClass({ id: priceDate.studyClass, description: `${priceDate.studyClass} - ${priceDate.studyClassDesc}` });
        }
    }, []);

    const errorMessages = (errotType: string) => {
        switch (errotType) {
            case 'invalidDate':
                return {
                    msg: 'Fecha Inválida',
                    valid: false
                };
            default:
                return {
                    msg: '',
                    valid: true
                };
        }
    };
    const handleSetStrudentType = (event: any) => {
        setStudentType(event.target.value);
    };

    const handleChangeDate = (date: Moment) => {
        setDate(date);
    };

    const onSubmit = () => {
        const req = {
            priceDateList: date && date.format('YYYY-MM-DD'),
            period: cod?.id,
            studyClass: studyClass && Number(studyClass.id),
            typeAlumn: studentType
        };
        if (!priceDate) {
            createPriceDate(req);
        } else {
            const request = { id: priceDate.id, modality: priceDate.modality, ...req };
            updatePriceDate(request);
        }
    };

    const validateForm = () => {
        return date && cod && studyClass && studentType;
    };

    const refreshList = () => {
        queryClient.invalidateQueries(['listSaleToolData']);
    };

    const createPriceDateCall = async (priceDate: any) => {
        const data = await saleToolServicies.createPriceDate(priceDate);
        return data;
    };
    const { mutate: createPriceDate, isLoading: isLoadingCreate } = useMutation(createPriceDateCall, {
        onSuccess: (res) => {
            onCancel();
            refreshList();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.recordCreatedSuccess')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const updatePriceDateCall = async (priceDate: any) => {
        const data = await saleToolServicies.updatePriceDate(priceDate);
        return data;
    };
    const { mutate: updatePriceDate, isLoading: isLoadingUpdate } = useMutation(updatePriceDateCall, {
        onSuccess: (res) => {
            onCancel();
            refreshList();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.recordUpdatedSuccess')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    return (
        <>
            <Modal open={isOpen} onClose={onCancel} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 550,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 4
                    }}
                >
                    <Box className="content_sale_tool">
                        <Typography className="module_title" data-testid="module-title" gutterBottom>
                            {priceDate ? t('titles.editRegistration') : t('titles.tNewRegistration')}
                        </Typography>
                    </Box>
                    <Typography>
                        <div className="filter__line_modal filter__line-custom_prices_3 mt-1">
                            <FormControl size="small" className="form__control_modal">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['DatePicker']}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale="es"
                                            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                                        >
                                            <DatePicker
                                                className="filter__textfield"
                                                format="DD/MM/YYYY"
                                                label="Fecha"
                                                localeText={{ fieldYearPlaceholder: (params) => 'A'.repeat(params.digitAmount) }}
                                                slotProps={{
                                                    textField: { size: 'small', helperText: errorMessageDate },
                                                    actionBar: {
                                                        actions: ['clear']
                                                    }
                                                }}
                                                onError={(newError) => setErrorDate(newError)}
                                                value={date}
                                                onChange={(newValue) => handleChangeDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl size="small" className="form__control_modal formCourse__matAutocomplete modal_period">
                                <Autocomplete
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={cod}
                                    options={codData || []}
                                    onChange={(e: any, newValue: any | null) => setCod(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={t('formFilter.period')} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className="filter__line_modal mt-1">
                            <FormControl size="small" className="form__control_modal formCourse__matAutocomplete">
                                <Autocomplete
                                    disabled={disabledFields}
                                    clearText={'Limpiar'}
                                    openText=""
                                    value={studyClass}
                                    options={studyClassData || []}
                                    onChange={(e: any, newValue: any | null) => setStudyClass(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={t('formFilter.studyClass')} />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className="new_log__line">
                            <FormControl size="small">
                                <FormLabel className="new_Display_TypeStudent">{t('formFilter.typeStudent')}</FormLabel>
                                <RadioGroup
                                    className="new_Display_TypeStudent_options"
                                    value={studentType}
                                    name="radio-buttons-group"
                                    onChange={handleSetStrudentType}
                                >
                                    {studentTypesOptions.map((item: any) => (
                                        <FormControlLabel
                                            value={item.value}
                                            key={item.id}
                                            control={<Radio />}
                                            label={item.label}
                                            style={{ marginBottom: '-15px' }}
                                            disabled={disabledFields}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Typography>
                    <Stack className="edit_Display_Button" direction="row" spacing={2}>
                        <Button color="error" variant="contained" onClick={onCancel}>
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!validDate || isLoadingCreate || !validateForm() || isLoadingUpdate}
                            startIcon={isLoadingCreate || isLoadingUpdate ? <CircularProgress size={20} color="inherit" /> : null}
                            onClick={onSubmit}
                        >
                            Confirmar
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default NewModal;
