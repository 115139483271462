import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Navbar from '../components/layout/Navbar/Navbar';
import CurrentPrices from '../components/modules/current-prices/current-prices';
import BulkLoadValidation from '../components/modules/bulk-load-validation/bulk-load-validation';
import LogDisplay from '../components/modules/bulk-load-validation/log-display/log-display';
import SaleTool from '../components/modules/sale-tool/sale-tool';
import BulkLoad from '../components/modules/bulk-load/bulk-load';
import Historical from '../components/modules/historical/historical';
import { PrivateRoute } from './private-route';
import { hasRole } from '../utils/utils';
import { AUTHORITIES } from '../utils/constants';
import { BillingProduct } from '../components/modules/billing-product/billing-product';
import { ExchangeTickets } from '../components/modules/exchange-tickets/exchange-tickets';

const AppRoutes = ({ appData }: any) => {
    return (
        <>
            <Navbar />
            <div className="layout__content" id="rmt_layoutContent">
                <Routes>
                    <Route key="redirect" path="*" element={<Navigate to="/current-prices" replace />} />
                    <Route
                        key="current-prices"
                        path="/current-prices"
                        element={
                            <PrivateRoute
                                isAllowed={
                                    !!appData.user &&
                                    hasRole(appData.user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS])
                                }
                            />
                        }
                    >
                        <Route path="" element={<CurrentPrices />} />
                    </Route>
                    <Route
                        key="bulk-load-validation"
                        path="/bulk-load-validation"
                        element={<PrivateRoute isAllowed={!!appData.user && hasRole(appData.user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS])} />}
                    >
                        <Route path="" element={<BulkLoadValidation />} />
                        <Route path=":csvId" element={<BulkLoadValidation />} />
                        <Route path="logDisplay/:csvId" element={<LogDisplay />} />
                        <Route path=":csvId/logDisplay/:csvId" element={<LogDisplay />} />
                    </Route>
                    <Route
                        key="bulk-load"
                        path="/bulk-load"
                        element={<PrivateRoute isAllowed={!!appData.user && hasRole(appData.user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS])} />}
                    >
                        <Route path="" element={<BulkLoad />} />
                    </Route>
                    <Route
                        key="sale-tool"
                        path="/sale-tool"
                        element={<PrivateRoute isAllowed={!!appData.user && hasRole(appData.user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS])} />}
                    >
                        <Route path="" element={<SaleTool />}/>
                    </Route>
                    <Route
                        key="historical"
                        path="/historical"
                        element={
                            <PrivateRoute
                                isAllowed={
                                    !!appData.user &&
                                    hasRole(appData.user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS])}/>}
                    >
                        <Route path="" element={<Historical />} />
                    </Route>
                    <Route
                        key="billing-product"
                        path="/billing-product"
                        element={
                            <PrivateRoute
                                isAllowed={
                                    !!appData.user &&
                                    hasRole(appData.user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS])}/>}
                    >
                        <Route path="" element={<BillingProduct />} />
                    </Route>
                    <Route
                        key="exchange-tickets"
                        path="/exchange-tickets"
                        element={
                            <PrivateRoute
                                isAllowed={
                                    !!appData.user &&
                                    hasRole(appData.user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS])}/>}
                    >
                        <Route path="" element={<ExchangeTickets />} />
                    </Route>
                </Routes>
            </div>
        </>
    );
};

export default AppRoutes;
