export const ROLES = ['ROLE_UE21_CARGA_PRECIOS', 'ROLE_UE21_VISTA_PRECIOS'];

export const AUTHORITIES = {
    ROLE_UE21_CARGA_PRECIOS: 'ROLE_UE21_CARGA_PRECIOS',
    ROLE_UE21_VISTA_PRECIOS: 'ROLE_UE21_VISTA_PRECIOS'
};

export const STATE_LOGIN = {
    user: {
        authorities: ['ROLE_UE21_CARGA_PRECIOS', 'ROLE_UE21_VISTA_PRECIOS'],
        id: 'MARTBRACAMONTE',
        name: 'MARTBRACAMONTE',
        email: null
    }
};

export const STATUS_ARTICLE = {
    DRAFT: 1,
    LOW: 2,
    APPROVED: 4,
    REFUSED: 3
};
export const STATUS_HISTORICAL = {
    DRAFT: 'Borrador',
    LOW: 'Baja',
    APPROVED: 'Aprobado',
    REFUSED: 'Rechazado'
};
export const STATUS_BLULKLOAD = {
    PROCESANDO_CARGA: 1,
    ARCHIVO_CARGADO: 2,
    ERROR_CARGA: 3,
    PROCESANDO: 4,
    PARCIALMENTE_PROCESADO: 5,
    PROCESADO: 6,
    ERROR_PROCESADO: 7,
    PRICING_LEGACY: 8
};
