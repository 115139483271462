import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = ({ isAllowed, redirectTo = '/current-prices', children }: any) => {
    if (!isAllowed) {
        return <Navigate to={redirectTo} replace />;
    }

    return children || <Outlet />;
};
