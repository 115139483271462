import { axiosInstance } from './api.service';
import { StudyClass } from '../models/resource.model';

export const saleToolServicies = {
    getStudyClass: async () => {
        const { data } = await axiosInstance.get('/resources/clase-de-estudio');
        const dataMap = data.map((item: StudyClass) => ({ id: item.id, description: item.descripcion }));
        return dataMap;
    },

    getCodPeriods: async () => {
        const { data } = await axiosInstance.get('/resources/codigos');
        const dataMap = data?.map((codigo: any) => ({ id: codigo, description: codigo }));
        return dataMap;
    },
    getSaleTool: async (page: number, pageSize: number = 10, filters?: any) => {
        const params = {
            idStudyClass: filters.studyClass,
            codigoPeriodo: filters.code,
            tipoAlumn: filters.studentTypeId,
            pageNo: page.toString(),
            pageSize: pageSize.toString()
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.get(`/instrumentoDeVenta/price-date?${query}`);
        return data;
    },
    createPriceDate: async function (priceDate: any) {
        const { data } = await axiosInstance.post('/instrumentoDeVenta/carga-price-date', priceDate);
        return data;
    },
    updatePriceDate: async function (priceDate: any) {
        const { data } = await axiosInstance.post('/instrumentoDeVenta/update-price-date', priceDate);
        return data;
    }
};
