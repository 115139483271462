import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    CircularProgress,
    TableFooter,
    TablePagination,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Refresh as RefreshIcon } from '@mui/icons-material/';
import './table-validation-search.scss';
import TablePaginationActions from '../../../shared/pagination/TablePaginationActions';
import TableMessage from '../../../shared/tableMessage/TableMessage';
import { RowTableBulkLoad } from './row-table-bulkoad/row-table-bulkload';

const TableValidationSearch = ({ isLoading, isFetching, fileData, page, handlePage, pageSize, totalElements, refetch }: any) => {
    const { t } = useTranslation();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, currenPage: number) => {
        handlePage(currenPage);
    };
    if (isLoading || isFetching) {
        return (
            <div className="flex-justify-content_center marginComponents">
                <CircularProgress id="rmt-loadingPrograms" color="success" />
            </div>
        );
    }

    if (fileData.totalElements === 0)
        return (
            <div className="marginComponents">
                {' '}
                <TableMessage msg={t('messages.resultsNotFount')} />{' '}
            </div>
        );

    return (
        <>
            <Button variant="outlined" className="table_logDisplay_button mt-2" type="button" startIcon={<RefreshIcon />} onClick={refetch}>
                Refrescar
            </Button>
            <TableContainer className="table__price_list" component={Paper}>
                <Table>
                    <TableBody className="table__body">
                        <TableRow className="table__bulkLoad_header">
                            <TableCell className="table__courseProgramCell">{t('tableCell.vID')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.vArchive')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.vPending')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.vDate')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.status')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.vUser')}</TableCell>
                            <TableCell className="table__courseProgramCell"></TableCell>
                        </TableRow>
                        <TableRow sx={{ boxShadow: 2 }} className="table__mainRow">
                            <TableCell className="table__cellAux">
                                <Table>
                                    <TableBody>
                                        {fileData?.lotes.map((item: any, i: number) => (
                                            <RowTableBulkLoad key={i} item={item} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={totalElements}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`;
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default TableValidationSearch;
