import React, { useEffect, useState } from 'react';
import { FilterExchangeTicketsHistorical } from './filter-exchange-tickets-historical/filter-exchange-tickets-historical';
import { TableExchangeTicketsHistorical } from './table-exchange-tickets-historical/table-exchange-tickets-historical';
import { useQuery } from 'react-query';
import { exchangeTicketsService } from '../../../../services/exchange-tickets.service';
import { Box, LinearProgress } from '@mui/material';

export const HistoricalExchangeTickets = () => {
    const pageSize = 10;
    const [filters, setFilters] = useState<any>({});
    const [page, setPage] = useState<number>(0);
    const [cancelRequest, setCancelRequest] = useState<boolean>(false);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [isLoadingDownload, setLoadingDownload] = useState(false);

    const {
        data: ticketsHistoricalData,
        isLoading,
        isFetching
    } = useQuery(
        ['ticketsHistoricalET', page, pageSize, filters],
        async () => {
            const ticketsData = await exchangeTicketsService.getTicketsHistoricalData(page, pageSize, filters);
            return ticketsData;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: !cancelRequest,
            onError: () => {
                setCancelRequest(true);
            },
            retry: false,
            cacheTime: 0
        }
    );

    useEffect(() => {
        return () => {
            setCancelRequest(true);
        };
    }, []);

    useEffect(() => {
        if (ticketsHistoricalData) {
            setTotalElements(ticketsHistoricalData?.totalElements);
        }
    }, [ticketsHistoricalData]);

    const handleFilters = (filters: any) => {
        setFilters(filters);
        setPage(0);
    };
    const handlePage = (page: number) => {
        setPage(page);
    };

    const handleDownloadCSV = async () => {
        setLoadingDownload(true);
        try {
            const response = await exchangeTicketsService.downloadCsvTicketsHistorical(filters);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'historical_tickets.csv');
            document.body.appendChild(link);
            link.click();
            setLoadingDownload(false);
        } catch (error) {
            setLoadingDownload(false);
        }
    };

    return (
        <>
            <Box className="create__controls">
                <Box className="create__line">
                    <FilterExchangeTicketsHistorical handleFilters={handleFilters} isFetching={isFetching} />
                </Box>
            </Box>
            {isLoadingDownload ? (
                <>
                    <h4 className="module_title">Descargando CSV...</h4>
                    <LinearProgress color="success" />
                </>
            ) : (
                <Box className="mt-2">
                    <TableExchangeTicketsHistorical
                        ticketsHistoricalData={ticketsHistoricalData}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        totalElements={totalElements}
                        pageSize={pageSize}
                        page={page}
                        handlePage={handlePage}
                        handleDownloadCSV={handleDownloadCSV}
                    />
                </Box>
            )}
        </>
    );
};
