import React, { useRef } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    IconButton,
    CircularProgress,
    TableFooter,
    TablePagination,
    Button
} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import '../sale-tool.scss';
import { formatDate } from '../../../../utils/formatDate';
import TableMessage from '../../../shared/tableMessage/TableMessage';
import TablePaginationActions from '../../../shared/pagination/TablePaginationActions';
import { Download } from '@mui/icons-material';
import { CSVLink } from 'react-csv';

const TableSaleTool = ({
    isLoading,
    isFetching,
    listSaleTool,
    page,
    handlePage,
    pageSize,
    totalElements,
    hadlePriceDateEdit,
    handleItemSelected,
    partialCheck,
    isCheckedAll,
    handleCheckedAll,
    selecteds,
    newRegistration,
    isRoleCargaPrecio
}: any) => {
    const { t } = useTranslation();
    const refs = useRef<any | null>(null);

    const headers = [
        { label: 'FECHA', key: 'priceDateList' },
        { label: 'CLASE_ESTUDIO', key: 'studyClassDesc' },
        { label: 'TIPO_ESTUDIANTE', key: 'typeAlumn' },
        { label: 'PERIODO', key: 'period' }
    ];

    if (isLoading || isFetching) {
        return (
            <div className="flex-justify-content_center">
                <CircularProgress id="rmt-loadingPrograms" color="success" />
            </div>
        );
    }
    if (listSaleTool?.length === 0) return <TableMessage msg={t('messages.resultsNotFount')} />;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, currenPage: number) => {
        handlePage(currenPage);
    };

    const handleDownloadCSV = () => {
        refs.current.link.click();
    };

    return (
        <>
            <Button
                variant="contained"
                component="label"
                onClick={newRegistration}
                startIcon={<PostAddIcon />}
                disabled={!isRoleCargaPrecio}
            >
                {t('buttons.newRegistration')}
            </Button>
            {(selecteds.some((item: any) => item?.checked) || isCheckedAll) && (
                <>
                    <Button variant="contained" type="button" className="ml-1" startIcon={<Download />} onClick={handleDownloadCSV}>
                        {t('buttons.download')}
                    </Button>
                    <CSVLink
                        data={selecteds.filter((item: any) => item.checked)}
                        headers={headers}
                        filename={'lista_fecha_precios.csv'}
                        ref={refs}
                        separator={';'}
                        enclosingCharacter={''}
                        uFEFF={false}
                    />{' '}
                    <div className="mt-1">
                        <small>
                            <b>{selecteds.filter((item: any) => item.checked).length} registros seleccionados</b>
                        </small>
                    </div>
                </>
            )}
            <TableContainer className="table__price_list" component={Paper}>
                <Table className="table__fixed-layout">
                    <TableBody className="table__body">
                        <TableRow className="table__massValidationSearchHeadLastFiles table__courseProgramHeadArticles">
                            <TableCell className="table__cell table__controlCell">
                                <Checkbox color="default" checked={isCheckedAll} indeterminate={partialCheck} onChange={(e) => handleCheckedAll(e)} />
                            </TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.date')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.iStudyClass')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.typeStudent')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.period')}</TableCell>
                            <TableCell className="table__courseProgramCell"></TableCell>
                        </TableRow>
                        <TableRow sx={{ boxShadow: 2 }} className="table__mainRow">
                            <TableCell className="table__cellAux">
                                <Table>
                                    <TableBody>
                                        {listSaleTool?.map((item: any, i: number) => (
                                            <TableRow
                                                className="table__row table__massValidationSearchBodyLastFiles"
                                                hover={true}
                                                sx={{ '& > *': { borderBottom: 'unset' } }}
                                                key={i}
                                            >
                                                <TableCell className="table__cell table__controlCell">
                                                    <Checkbox checked={item?.checked} onChange={(e) => handleItemSelected(e, item)} />
                                                </TableCell>
                                                <TableCell className="table__courseProgramCellBody table__fixed-cell">
                                                    {formatDate(item.priceDateList)}
                                                </TableCell>
                                                <TableCell className="table__courseProgramCellBody table__fixed-cell">
                                                    {item.studyClassDesc}
                                                </TableCell>
                                                <TableCell className="table__courseProgramCellBody table__fixed-cell">{item.typeAlumn}</TableCell>
                                                <TableCell className="table__courseProgramCellBody table__fixed-cell">{item.period}</TableCell>
                                                <TableCell className="table__cell table__controlCell">
                                                    <Tooltip style={{ paddingInline: '5px' }} title={t('tooltips.editLog')}>
                                                        <Box>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                id="rmt-syllabusEdit"
                                                                onClick={() => hadlePriceDateEdit(item)}
                                                                disabled={!isRoleCargaPrecio}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={totalElements || 0}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`;
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default TableSaleTool;
