import React, { useEffect, useState, MouseEvent } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableMessage from '../../../../shared/tableMessage/TableMessage';
import { RowTableExchangeTicketsHistorical } from './row-table-exchange-tickets-historical/row-table-exchange-tickets-historical';
import TablePaginationActions from '../../../../shared/pagination/TablePaginationActions';
import { Download as DownloadIcon } from '@mui/icons-material';

interface tableHistoricalInterface {
    ticketsHistoricalData: any; //! actualizar por tickets hisotrical cuando esté el filtro
    isLoading: boolean;
    isFetching: boolean;
    totalElements: number;
    pageSize: number;
    page: number;
    handlePage: Function;
    handleDownloadCSV: Function;
}
export const TableExchangeTicketsHistorical = ({
    ticketsHistoricalData,
    isLoading,
    isFetching,
    totalElements,
    pageSize,
    page,
    handlePage,
    handleDownloadCSV
}: tableHistoricalInterface) => {
    const { t } = useTranslation();
    const [showNoResultsMessage, setShowNoResultsMessage] = useState<boolean>(false);

    useEffect(() => {
        // Le da 2 segundos solo si no encuentra resultados ya que la api en ocaciones tarda en responder
        setShowNoResultsMessage(false);
        const timer = setTimeout(() => {
            if (!isLoading && !isFetching) {
                setShowNoResultsMessage(true);
            }
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [isLoading, isFetching]);

    if (isLoading || isFetching || ticketsHistoricalData.priceIntercambioDtos?.length === 0) {
        if (!showNoResultsMessage) {
            return (
                <div className="flex-justify-content_center">
                    <CircularProgress id="rmt-loadingPrograms" color="success" />
                </div>
            );
        }
        if (ticketsHistoricalData.priceIntercambioDtos?.length === 0) return <TableMessage msg={t('messages.resultsNotFount')} />;
    }

    const handleDownload = () => {
        handleDownloadCSV();
    };

    const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, currenPage: number) => {
        handlePage(currenPage);
    };

    return (
        <>
            <Box>
                <Button variant="contained" type="button" startIcon={<DownloadIcon />} onClick={handleDownload}>
                    {t('buttons.download')}
                </Button>
            </Box>
            <TableContainer className="table__price_list" component={Paper}>
                <Table>
                    <TableBody className="table__body">
                        <TableRow className="table__exchange_tickets_historical_Header">
                            <TableCell className="table__courseProgramCell">{t('tableCell.dateFrom')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.dateUntil')}</TableCell>
                            {<TableCell className="table__courseProgramCell">{t('tableCell.numberSubjects')}</TableCell>}
                            <TableCell className="table__courseProgramCell">{t('tableCell.price')}</TableCell>
                            <TableCell className="table__courseProgramCell">
                                {ticketsHistoricalData?.priceIntercambioDtos[0]?.arancel !== 'Graduacion'
                                    ? t('tableCell.modalityDes')
                                    : 'Clase de estudio'}
                            </TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.active')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.user')}</TableCell>
                        </TableRow>
                        {ticketsHistoricalData?.priceIntercambioDtos.map((item: any, i: number) => (
                            <RowTableExchangeTicketsHistorical key={i} item={item} />
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={totalElements || 0}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`;
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};
