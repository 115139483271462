/* eslint-disable quote-props */
import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../../../../../utils/formatDate';
import { formatPrice } from '../../../../../../utils/formatPrice';
interface rowTableHistoricalInterface {
    item: any;
}

const studyClassDictionary: any = {
    'CURSOS CLOUD 21': 19,
    'CURSOS EVENTOS Y WORKS': 15,
    'CURSOS INTERNOS': 14,
    'DEFAULT DISTANCIA': 48,
    'DIPLOMADOS CLOUD 21': 17,
    'MAESTRIA VIRTUAL': 45,
    MAESTRIAS: 13,
    'OTROS WEB': 0,
    'PosMaestria DISTANCIA': 49,
    'PREGRADO PRESENCIAL': 47,
    'PRESENCIAL CENCAP': 16,
    SECUNDARIO: 25,
    'CERTIFICADOS CLOUD 21': 18,
    'DIPLOMATURA MTD': 2,
    EXTRAGRADO: 10,
    'GRADO MTD': 1,
    'GRADO PRESENCIAL': 7,
    'GRADO PRESENCIAL .': 20,
    'GRADO PRESENCIAL HOME': 4,
    PREGRADO: 11,
    'ACTIVIDAD DISTANCIA': 46,
    null: 'N/A'
};

export const RowTableExchangeTicketsHistorical = ({ item }: rowTableHistoricalInterface) => {
    return (
        <>
            <TableRow sx={{ boxShadow: 2, textAlign: 'center' }} className="table__mainRow">
                <TableCell className="table__cellAux">
                    <Table>
                        <TableBody>
                            <TableRow
                                className="table__row table__row_exchange_tickets_historical"
                                hover={true}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                <TableCell className="table__exchange_tickets_CellBody">{formatDate(item.fechaDesde)}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">{formatDate(item.fechaHasta)}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">{item.cantMaterias}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">{formatPrice(item.price)}</TableCell>
                                {item.arancel === 'Intercambio' && (
                                    <TableCell className="table__exchange_tickets_CellBody">
                                        {item.idModalidad + ' - ' + item.descripcionModalidad}
                                    </TableCell>
                                )}
                                {item.arancel === 'Graduacion' && (
                                    <TableCell className="table__exchange_tickets_CellBody">
                                        {studyClassDictionary[item.descStudyClass] +
                                            ' - ' +
                                            (item.descStudyClass === null ? 'N/A' : item.descStudyClass)}
                                    </TableCell>
                                )}
                                <TableCell className="table__exchange_tickets_CellBody">{item.activo}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">{item.usuario}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    );
};
