import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../layout/Toolbar/Toolbar';
import './exchange-tickets.scss';
import { PriceExchangeTickets } from './price-exchange-tickets/price-exchange-tickets';
import { HistoricalExchangeTickets } from './historical-exchange-tickets/historical-exchange-tickets';
import { GradiatuionExchangeTickets } from './graduation-exchange-tickets/graduation-exchange-tickets';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </Box>
    );
};

export const ExchangeTickets: React.FC = () => { //! ARRANCA EL COMPONENTE
    const { t } = useTranslation();
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getTabAccessibilityProps = (index: number) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    });

    return (
        <>
            <Box className="layout__toolbar">
                <Toolbar />
            </Box>
            <Box className="container">
                <Box className="content_current_prices">
                    <Typography className="module_title" data-testid="module-title" gutterBottom>
                        {t('titles.exchangeTickets')}
                    </Typography>
                    <Typography className="module_subtitle1">{t('subtitles.exchangeTickets')}</Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: '#ccc' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Intercambio" {...getTabAccessibilityProps(0)} />
                            <Tab label="Graduación" {...getTabAccessibilityProps(1)} />
                            <Tab label="Histórico" {...getTabAccessibilityProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <PriceExchangeTickets />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <GradiatuionExchangeTickets />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <HistoricalExchangeTickets />
                    </CustomTabPanel>
                </Box>
            </Box>
        </>
    );
};
