import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, List, ListItem, ListItemIcon, Tooltip } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { PendingActions as PendingActionsIcon, CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DehazeIcon from '@mui/icons-material/Dehaze';
import './toolbar.css';
import { NavLink } from 'react-router-dom';
import { hasRole } from '../../../utils/utils';
import { AppContext } from '../../../App.context';
import { AUTHORITIES } from '../../../utils/constants';

const Toolbar = () => {
    const { t } = useTranslation();

    const appContext = useContext(AppContext);
    const user = appContext?.state.user;

    return (
        <div className="Sidebar__Container">
            <Drawer className="Sidebar__Drawer" variant="permanent">
                <List className="sidebar__list" id="rmt-toolbar">
                    {hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS]) && (
                        <ListItem key="1" button className="Sidebar__Drawer__List__Item" component={NavLink} to="/current-prices" tabIndex={-1}>
                            <Tooltip title={t('titles.prices')}>
                                <ListItemIcon id="rmt-toolbar-programs" className="Sidebar__Drawer__List__Item__Icon">
                                    <AssignmentIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    )}
                    {hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS]) && (
                        <ListItem key="2" button className="Sidebar__Drawer__List__Item" component={NavLink} to="/bulk-load" tabIndex={-1}>
                            <Tooltip title={t('titles.bulkLoad')}>
                                <ListItemIcon id="rmt-toolbar-courses" className="Sidebar__Drawer__List__Item__Icon">
                                    <UploadFileIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    )}
                    {hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS]) && (
                        <ListItem key="3" button className="Sidebar__Drawer__List__Item" component={NavLink} to="/bulk-load-validation" tabIndex={-1}>
                            <Tooltip title={t('titles.bulkLoadValidation')}>
                                <ListItemIcon id="rmt-toolbar-bulk-load-validation" className="Sidebar__Drawer__List__Item__Icon">
                                    <DehazeIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    )}
                    {hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS]) && (
                        <ListItem key="4" button className="Sidebar__Drawer__List__Item" component={NavLink} to="/historical" tabIndex={-1}>
                            <Tooltip title={t('titles.historical')}>
                                <ListItemIcon id="" className="Sidebar__Drawer__List__Item__Icon">
                                    <PendingActionsIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    )}
                    {hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS]) && (
                        <ListItem key="5" button className="Sidebar__Drawer__List__Item" component={NavLink} to="/sale-tool" tabIndex={-1}>
                            <Tooltip title={t('titles.tSales')}>
                                <ListItemIcon id="" className="Sidebar__Drawer__List__Item__Icon">
                                    <CalendarTodayIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    )}
                    {hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS]) && (
                        <ListItem key="6" button className="Sidebar__Drawer__List__Item" component={NavLink} to="/billing-product" tabIndex={-1}>
                            <Tooltip title={t('titles.billingProductIcon')}>
                                <ListItemIcon id="" className="Sidebar__Drawer__List__Item__Icon">
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    )}
                    {hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS, AUTHORITIES.ROLE_UE21_VISTA_PRECIOS]) && (
                        <ListItem key="7" button className="Sidebar__Drawer__List__Item" component={NavLink} to="/exchange-tickets" tabIndex={-1}>
                            <Tooltip title={t('titles.exchangeTicketsIcon')}>
                                <ListItemIcon id="" className="Sidebar__Drawer__List__Item__Icon">
                                    <ConfirmationNumberIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    )}
                </List>
            </Drawer>
        </div>
    );
};

export default Toolbar;
