import { Modality, Resource, GraduationTickets, studyClass2 } from '../models/resource.model';
import { axiosInstance } from './api.service';

export const exchangeTicketsService = {
    getByModalidadData: async (filters: any = {}) => {
        const params = {
            idModalidad: filters?.idModalidad ?? 99
        };
        const searchParams = new URLSearchParams(params);
        const query = searchParams.toString();
        const { data } = await axiosInstance.get(`/precioIntercambio/getByModalidad?${query}`);
        return data;
    },
    getTicketsHistoricalData: async (page: number = 0, pageSize: number = 10, filters?: any) => {
        const params = {
            modalidad: filters.modalidad,
            idStudyClass: filters.studyClass,
            cantMaterias: filters.cantMaterias,
            estado: filters.estado,
            fechaDesde: filters.startDate,
            fechaHasta: filters.endDate,
            pageNo: page.toString(),
            pageSize: pageSize.toString(),
            tipoArancelId: filters.tipoArancelId || '1'
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.get(`/precioIntercambio/preciosHistoricos?${query}`);
        return data;
    },

    getModality: async () => {
        const { data } = await axiosInstance.get('/resources/modalidadesCalipso'); // Modalidad
        const dataMap = data.map((item: Modality) => ({ id: item.id, description: item.descripcion, modalidad: item.modalidad }));
        return dataMap;
    },
    getQuantityMaterials: async () => {
        const { data } = await axiosInstance.get('/resources/ticket'); // Cantidad de materias
        const dataMap = data.map((item: Resource) => ({ id: item.id, description: item.descripcion }));
        return dataMap;
    },
    getTypeState: async () => {
        const { data } = await axiosInstance.get('/resources/turnos'); // Tipo de estado
        const dataMap = data.map((item: Resource) => ({ id: item.id, description: item.descripcion }));
        return dataMap;
    },
    getPriceGraduacion: async () => {
        const { data } = await axiosInstance.get('/precioIntercambio/getPriceGraduacion'); // Tabla graduación
        const dataMap = data.map((item: GraduationTickets) => ({
            id: item.id,
            idModalidad: item.idModalidad,
            descripcionModalidad: item.descripcionModalidad,
            tipoTicket: item.tipoTicket,
            articulo: item.articulo,
            cantMaterias: item.cantMaterias,
            price: item.price,
            activo: item.activo,
            fechaDesde: item.fechaDesde,
            fechaHasta: item.fechaHasta,
            usuario: item.usuario,
            tipoArancel: item.tipoArancel,
            idStudyClass: item.idStudyClass,
            centroDeCostos: item.centroDeCostos
        }));
        return dataMap;
    },

    updatePriceTicketMutation: async function (request: any) {
        // update individual
        try {
            const { data } = await axiosInstance.post(
                `/precioIntercambio/updatePriceIntercambio?id=${request.id}&usuario=${request.usuario}&precio=${request.precio}`
            );
            return data;
        } catch (error) {
            console.warn('Error al actualizar el precio:', error);
            // throw error;
        }
    },
    updateAllPriceTicketMutation: async function (request: any) {
        // update grupal
        try {
            const { data } = await axiosInstance.post(
                `/precioIntercambio/updateAllPriceIntercambio?usuario=${request.usuario}&precio=${request.precio}&cantMaterias=${request.cantMaterias}`
            );
            return data;
        } catch (error) {
            console.warn('Error al actualizar los precios:', error);
            // throw error;
        }
    },
    updateGraduationPriceTicketMutation: async function (request: any) {
        // update individual
        try {
            const { data } = await axiosInstance.post(
                `/precioIntercambio/updatePriceGraduacion?id=${request.id}&usuario=${request.usuario}&precio=${request.precio}&centroCosto=${request.centroCosto}&articulo=${request.articulo}`
            );
            return data;
        } catch (error) {
            console.warn('Error al actualizar el precio:', error);
            // throw error;
        }
    },
    updateAllGraduationPriceTicketMutation: async function (request: any) {
        // update grupal Graduación
        try {
            const { data } = await axiosInstance.post(
                `/precioIntercambio/updateAllPriceGraduacion?usuario=${request.usuario}&precio=${request.precio}&centroCosto=${request.centroCosto}&articulo=${request.articulo}`
            );
            return data;
        } catch (error) {
            console.warn('Error al actualizar los precios:', error);
            // throw error;
        }
    },
    downloadCsvTicketsHistorical: async (filters?: any) => {
        const params = {
            modalidad: filters.modalidad,
            idStudyClass: filters.studyClass,
            cantMaterias: filters.cantMaterias,
            estado: filters.estado,
            fechaDesde: filters.startDate,
            fechaHasta: filters.endDate,
            tipoArancelId: filters.tipoArancelId || '1'
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        return axiosInstance({
            url: `/precioIntercambio/exportar-csv?${query}`,
            method: 'GET',
            responseType: 'blob',
            headers: { 'Content-Type': 'application/json' }
        });
    },
    getStudyClassWithoutPrice: async () => {
        const { data } = await axiosInstance.get('/resources/study-class-without-price');
        const dataMap = data.map((item: studyClass2) => ({ id: item.id, description: item.nombre }));
        return dataMap;
    },
    postGraduationTicket: async (ticket: any) => {
        try {
            const { data } = await axiosInstance.post(
                `/precioIntercambio/saveTicketGraduacion?precio=${ticket.price}&usuario=${ticket.user}&idStudyClass=${ticket.id}&descStudyClass=${ticket.desc}&centroCosto=${ticket.centroCosto}&articulo=${ticket.articulo}`
            );
            return data;
        } catch (error) {
            console.log(error);
        }
    },
    getAllStudyClasses: async () => {
        const { data } = await axiosInstance.get('/resources/study-class');

        const dataMap = data.map((item: studyClass2) => ({ id: item.id, description: item.nombre }));

        return dataMap;
    }
};
