import React, { useState, useEffect } from 'react';
import { Box, Chip, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { STATUS_BLULKLOAD } from '../../../../../utils/constants';
import { formatDate } from '../../../../../utils/formatDate';

export const RowTableBulkLoad = ({ item }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [color, setColor] = useState<any>('default');

    const handleLogValidation = (idSCV: number) => {
        navigate('logDisplay/' + idSCV);
    };

    useEffect(() => {
        let color = 'default';
        if (item.estado === STATUS_BLULKLOAD.ARCHIVO_CARGADO || item.estado === STATUS_BLULKLOAD.PARCIALMENTE_PROCESADO) {
            color = 'info';
        }
        if (item.estado === STATUS_BLULKLOAD.PROCESADO) {
            color = 'success';
        }
        if (item.estado === STATUS_BLULKLOAD.PROCESANDO_CARGA || item.estado === STATUS_BLULKLOAD.PROCESANDO) {
            color = 'warning';
        }
        if (item.estado === STATUS_BLULKLOAD.ERROR_CARGA || item.estado === STATUS_BLULKLOAD.ERROR_PROCESADO) {
            color = 'error';
        }
        if (item.estado === STATUS_BLULKLOAD.PRICING_LEGACY) {
            color = 'default';
        }
        setColor(color);
    }, [item]);

    return (
        <TableRow className="table__row table__bulkLoad_Row_body" hover={true} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell className="table__courseProgramCellBody">{item.id}</TableCell>
            <TableCell className="table__courseProgramCellBody">{item.nombreArchivo}</TableCell>
            <TableCell className="table__courseProgramCellBody">
                {item.countPendientes}/ {item.countTotal}
            </TableCell>
            <TableCell className="table__courseProgramCellBody">{formatDate(item.fechaCarga)}</TableCell>
            <TableCell className="table__courseProgramCellBody">
                <Chip label={item.estadoDescripcion} color={color} />
            </TableCell>
            <TableCell className="table__courseProgramCellBody">{item.usuario}</TableCell>
            <TableCell className="table__courseProgramCellBody">
                {
                    <Box className="tableIcons">
                        <Tooltip title={t('tooltips.vVisualize')} placement="top" arrow>
                            <span>
                                <IconButton aria-label="visibility" onClick={() => handleLogValidation(item.id)} disabled={color === 'warning' || color === 'default'}>
                                    <VisibilityIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                }
            </TableCell>
        </TableRow>
    );
};
