import { Box, Button, CircularProgress, FormControl, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { exchangeTicketsService } from '../../../../../services/exchange-tickets.service';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../../App.context';
import { appReducerCases } from '../../../../../reducers/types';

interface ModalPriceInterface {
    isOpen: any;
    onCancel: any;
    setIsOpen: any;
    modalityType: any;
    priceTicketEdit: any;
    refetch: any;
    isCheckedAllPrice: boolean;
    user: any;
}

export const ModalEditPrice = ({
    isOpen,
    onCancel,
    setIsOpen,
    modalityType,
    priceTicketEdit,
    refetch,
    isCheckedAllPrice,
    user
}: ModalPriceInterface) => {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const [priceTicket, setPriceTicket] = useState<number | null>(null);
    const [identificador, setidentificador] = useState<number | null>(null);
    const [cantMaterias, setcantMaterias] = useState<number>(0);
    const [isSamePrice, setisSamePrice] = useState<boolean>(true);

    useEffect(() => {
        if (priceTicketEdit) {
            setidentificador(priceTicketEdit.id);
            setcantMaterias(Number(priceTicketEdit.cantMaterias));
            setPriceTicket(Number(priceTicketEdit?.price));
        }
    }, []);

    useEffect(() => {
        priceTicketEdit?.price === priceTicket && !isCheckedAllPrice ? setisSamePrice(true) : setisSamePrice(false);
    }, [priceTicket]);

    const onSubmit = () => {
        if (!isCheckedAllPrice) {
            const req = {
                id: priceTicketEdit?.id,
                usuario: user.name,
                precio: priceTicket
            };
            updatePrice(req);
        } else {
            const req = {
                usuario: user.name,
                precio: priceTicket,
                cantMaterias: cantMaterias
            };
            updatePriceAll(req);
        }
    };

    const updatePriceTicketCall = async (req: any) => {
        const data = await exchangeTicketsService.updatePriceTicketMutation(req);
        return data;
    };

    const { mutate: updatePrice, isLoading: isLoadingUpdate } = useMutation(updatePriceTicketCall, {
        // onSuccess: (res) => {
        //     setIsOpen(false);
        //     refetch();
        // },
        // onError: (error: any) => {
        //     console.log('No pudo editar', error);
        // }
        onSuccess: (res) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editedPrice')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
            setIsOpen(false);
            refetch();
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });
    const updateAllPriceTicketCall = async (req: any) => {
        const data = await exchangeTicketsService.updateAllPriceTicketMutation(req);
        return data;
    };
    const { mutate: updatePriceAll, isLoading: isLoadingUpdateAll } = useMutation(updateAllPriceTicketCall, {
        // onSuccess: (res) => {
        //     setIsOpen(false);
        //     refetch();
        // },
        // onError: (error: any) => {
        //     console.log('No pudo editar', error);
        // }
        onSuccess: (res) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editedPriceAll')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
            setIsOpen(false);
            refetch();
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const validateForm = () => {
        return !!identificador && !!priceTicket && !!modalityType;
    };

    return (
        <>
            <Modal open={isOpen} onClose={onCancel} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        // poner estilos en sass
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 450,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 4
                    }}
                >
                    <Box className="content_sale_tool">
                        <Typography className="module_title" data-testid="module-title" gutterBottom>
                            Editar Precio
                        </Typography>
                    </Box>
                    <Box>
                        <div className="filter__line filter__line-2">
                            <FormControl size="small" className="form__control">
                                <Typography className="module_subtitle1">Cantidad de materias:&nbsp;&nbsp;&nbsp;&nbsp;{cantMaterias}</Typography>
                            </FormControl>
                            <FormControl size="small" className="form__control">
                                <TextField
                                    label="Precio"
                                    id="outlined-size-small"
                                    size="small"
                                    type="text"
                                    value={priceTicket !== null ? priceTicket.toString() : ''}
                                    onChange={(ev) => {
                                        const newValue = ev.target.value.replace(/\s/g, '');
                                        if (newValue === '' || (!isNaN(Number(newValue)) && !newValue.startsWith('-') && newValue.length <= 10)) {
                                            setPriceTicket(newValue !== '' ? Number(newValue) : null);
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            maxLength: 10
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                    </Box>
                    <div className="mt-1">
                        <Stack className="edit_Display_Button" direction="row" spacing={2}>
                            <Button color="error" variant="contained" onClick={onCancel}>
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={isLoadingUpdate || isSamePrice || !validateForm() || isLoadingUpdateAll}
                                startIcon={isLoadingUpdate ? <CircularProgress size={20} color="inherit" /> : null}
                                onClick={onSubmit}
                            >
                                Confirmar
                            </Button>
                        </Stack>
                    </div>
                </Box>
            </Modal>
        </>
    );
};
