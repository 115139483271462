import React, { useState, useEffect, useContext } from 'react';
import Toolbar from '../../layout/Toolbar/Toolbar';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableSaleTool from './table-sale-tool/table-sale-tool';
import NewModal from './new-modal/new-modal';
import FilterSaleTool from './filter-sale-tool/filter-sale-tool';
import { useQuery } from 'react-query';
import { saleToolServicies } from '../../../services/sale-tool.service';
import { hasRole } from '../../../utils/utils';
import { AUTHORITIES } from '../../../utils/constants';
import { AppContext } from '../../../App.context';

const SaleTool = () => {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const user = appContext?.state.user;
    const [totalElements, setTotalElements] = useState<number>(0);
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState<any>({});
    const [page, setPage] = useState(0);
    const [priceDate, setPriceDate] = useState(null);
    const [isPartialCheck, setPartialCheck] = useState(false);
    const [isCheckedAll, setCheckedAll] = useState(false);
    const [listSaleTool, setListSaleTool] = useState<any[]>([]);
    const [selecteds, setSelecteds] = useState<any[]>([]);
    const pageSize = 10;
    const [isRoleCargaPrecio, setisRoleCargaPrecio] = useState(false);

    const { data: listSaleToolData, isFetching } = useQuery(
        ['listSaleToolData', page, pageSize, filters],
        async () => {
            const data = await saleToolServicies.getSaleTool(page, pageSize, filters);
            return data;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        if (listSaleToolData) {
            const data = listSaleToolData?.priceDateList.map((item: any) => {
                const result = selecteds.find((elem) => elem.id === item.id);
                if (result) {
                    return { ...item, checked: result.checked };
                }
                return { ...item, checked: false };
            });
            setPartialCheck(data.some((item: any) => item?.checked));
            setCheckedAll(data.every((item: any) => item?.checked));
            setListSaleTool(data);
            setTotalElements(listSaleToolData?.totalElements);
        }
    }, [listSaleToolData]);

    useEffect(() => {
        setisRoleCargaPrecio(hasRole(user, [AUTHORITIES.ROLE_UE21_CARGA_PRECIOS]));
    }, []);

    const handleFilters = (filters: any) => {
        setFilters(filters);
        setPage(0);
    };
    const handleOpenModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    const handlePage = (page: number) => {
        setPage(page);
    };

    const hadlePriceDateEdit = (priceDate: any) => {
        setPriceDate(priceDate);
        handleOpenModal();
    };

    const newRegistration = () => {
        setPriceDate(null);
        handleOpenModal();
    };

    const handleItemSelected = (e: any, item: any) => {
        const checked = e.target.checked;
        const newList = listSaleTool.map((elem) => {
            if (
                elem.id === item.id
            ) {
                return { ...elem, checked };
            }
            return { ...elem };
        });

        const selectedItemIndex = selecteds.findIndex(
            (elem) =>
                elem.id === item.id
        );

        if (!checked) {
            if (selectedItemIndex > -1) {
                selecteds.splice(selectedItemIndex, 1);
                setSelecteds([...selecteds]);
            }
        } else {
            if (selectedItemIndex > -1) {
                selecteds[selectedItemIndex].checked = true;
                setSelecteds([...selecteds]);
            } else {
                setSelecteds([...selecteds, { ...item, checked: true }]);
            }
        }

        const atLeastChecked = newList.some((item) => item?.checked);
        setPartialCheck(atLeastChecked);
        setListSaleTool(newList);
    };

    const handleCheckedAll = (e: any) => {
        const checked = e.target.checked;
        const newList = listSaleTool.map((item: any) => ({ ...item, checked }));

        const newSelecteds = checked
            ? [
                ...selecteds,
                ...newList.filter(
                    (item) =>
                        !selecteds.some(
                            (selItem) =>
                                item.id === selItem.id
                        )
                )
            ]
            : selecteds.filter(
                (item) =>
                    !newList.some(
                        (newItem) =>
                            item.id === newItem.id
                    )
            );
        setPartialCheck(false);
        setCheckedAll(checked);
        setListSaleTool(newList);
        setSelecteds(newSelecteds);
    };

    return (
        <>
            <div className="layout__toolbar">
                <Toolbar />
            </div>
            <div className="container">
                <Box className="content_sale_tool">
                    <Typography className="module_title" data-testid="module-title" gutterBottom>
                        {t('titles.tSales')}
                    </Typography>
                    <Typography className="module_subtitle1">{t('subtitles.subtitleSaleTool')}</Typography>
                </Box>
                <div className="create__controls">
                    <div className="create__line">
                        <Box sx={{ marginRight: '10px' }}>
                            <FilterSaleTool handleFilters={handleFilters} />
                        </Box>
                    </div>
                </div>
                <TableSaleTool
                    listSaleTool={listSaleTool}
                    totalElements={totalElements}
                    isFetching={isFetching}
                    page={page}
                    handlePage={handlePage}
                    pageSize={pageSize}
                    hadlePriceDateEdit={hadlePriceDateEdit}
                    handleItemSelected={handleItemSelected}
                    partialCheck={isPartialCheck}
                    isCheckedAll={isCheckedAll}
                    handleCheckedAll={handleCheckedAll}
                    selecteds={selecteds}
                    newRegistration={newRegistration}
                    isRoleCargaPrecio={isRoleCargaPrecio}
                />
            </div>
            {isOpen && <NewModal isOpen={isOpen} onCancel={closeModal} priceDate={priceDate} />}
        </>
    );
};

export default SaleTool;
