import { Box, Button, CircularProgress, FormControl, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { exchangeTicketsService } from '../../../../../services/exchange-tickets.service';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../../App.context';
import { appReducerCases } from '../../../../../reducers/types';
import './modal-edit-graduation.scss';

interface ModalPriceInterface {
    isOpen: any;
    onCancel: any;
    setIsOpen: any;
    graduationData: any;
    priceTicketEdit: any;
    refetch: any;
    isAllGraduationSelected: boolean;
    user: any;
}

const studyClassDictionary: any = {
    19: 'CURSOS CLOUD 21',
    15: 'CURSOS EVENTOS Y WORKS',
    14: 'CURSOS INTERNOS',
    48: 'DEFAULT DISTANCIA',
    17: 'DIPLOMADOS CLOUD 21',
    45: 'MAESTRIA VIRTUAL',
    13: 'MAESTRIAS',
    0: 'OTROS WEB',
    49: 'PosMaestria DISTANCIA',
    47: 'PREGRADO PRESENCIAL',
    16: 'PRESENCIAL CENCAP',
    25: 'SECUNDARIO',
    18: 'CERTIFICADOS CLOUD 21',
    2: 'DIPLOMATURA MTD',
    10: 'EXTRAGRADO',
    1: 'GRADO MTD',
    7: 'GRADO PRESENCIAL',
    20: 'GRADO PRESENCIAL .',
    4: 'GRADO PRESENCIAL HOME',
    11: 'PREGRADO',
    46: 'ACTIVIDAD DISTANCIA'
};

export const ModalEditPrice = ({
    isOpen,
    onCancel,
    setIsOpen,
    graduationData,
    priceTicketEdit,
    refetch,
    isAllGraduationSelected,
    user
}: ModalPriceInterface) => {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const [priceTicket, setPriceTicket] = useState<number | null>(null);
    const [isSamePrice, setisSamePrice] = useState<boolean>(true);
    const [isSameId, setIsSameId] = useState<boolean>(true);
    const [isSameArticulo, setIsSameArticulo] = useState<boolean>(true);
    const [claseEstudioDescripcion, setClaseEstudioDescripcion] = useState<string>('');
    const [IDCentroDeCostos, setIDCentroDeCostos] = useState<string>('');
    const [articulo, setArticulo] = useState<string>('');

    useEffect(() => {
        if (priceTicketEdit && !isAllGraduationSelected) {
            setClaseEstudioDescripcion(studyClassDictionary[priceTicketEdit.idStudyClass]);
            setIDCentroDeCostos(priceTicketEdit.centroDeCostos);
            setArticulo(priceTicketEdit.articulo || '');
            setPriceTicket(Number(priceTicketEdit?.price));
        }
        if (isAllGraduationSelected) {
            setClaseEstudioDescripcion('Todas');
            setPriceTicket(null);
        }
    }, []);

    useEffect(() => {
        priceTicketEdit?.price === priceTicket && !isAllGraduationSelected ? setisSamePrice(true) : setisSamePrice(false);
    }, [priceTicket]);

    useEffect(() => {
        priceTicketEdit?.articulo === IDCentroDeCostos && !isAllGraduationSelected ? setIsSameId(true) : setIsSameId(false);
    }, [IDCentroDeCostos]);

    useEffect(() => {
        priceTicketEdit?.articulo === articulo && !isAllGraduationSelected ? setIsSameArticulo(true) : setIsSameArticulo(false);
    }, [articulo]);

    const onSubmit = () => {
        if (!isAllGraduationSelected) {
            const req = {
                id: priceTicketEdit?.id,
                usuario: user.name,
                precio: priceTicket,
                centroCosto: IDCentroDeCostos,
                articulo: articulo
            };

            updatePrice(req);
        } else {
            const req = {
                usuario: user.name,
                precio: priceTicket,
                centroCosto: IDCentroDeCostos,
                articulo
            };
            updatePriceAll(req);
        }
    };

    const updatePriceGraduationTicketCall = async (req: any) => {
        const data = await exchangeTicketsService.updateGraduationPriceTicketMutation(req);
        return data;
    };

    const { mutate: updatePrice, isLoading: isLoadingUpdate } = useMutation(updatePriceGraduationTicketCall, {
        onSuccess: (res) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editedPrice')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
            setIsOpen(false);
            refetch();
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });
    const updateAllGraduationPriceTicketCall = async (req: any) => {
        const data = await exchangeTicketsService.updateAllGraduationPriceTicketMutation(req);
        return data;
    };
    const { mutate: updatePriceAll, isLoading: isLoadingUpdateAll } = useMutation(updateAllGraduationPriceTicketCall, {
        onSuccess: (res) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editedPriceAll')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
            setIsOpen(false);
            refetch();
        },
        onError: (error: any) => {
            const err = error.response.data.message || '';
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' ' + err
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const validateForm = () => {
        return !!priceTicket && !!graduationData;
    };

    return (
        <Modal open={isOpen} onClose={onCancel} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box
                sx={{
                    // poner estilos en sass
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4
                }}
            >
                <Box className="content_sale_tool">
                    <Typography className="module_title" data-testid="module-title" gutterBottom>
                        Editar Precio
                    </Typography>
                </Box>
                <Box>
                    <div className="filter__line filter__line-2">
                        <FormControl size="small" className="form__control">
                            <Typography className="module_subtitle1 flex flex-col">
                                <span>Clase de Estudio:</span>
                                <span>{claseEstudioDescripcion}</span>
                            </Typography>
                        </FormControl>
                        <FormControl size="small" className="form__control">
                            <TextField
                                label="Precio"
                                id="outlined-size-small"
                                size="small"
                                type="text"
                                value={priceTicket !== null ? priceTicket.toString() : ''}
                                onChange={(ev) => {
                                    const newValue = ev.target.value.replace(/\s/g, '');
                                    if (newValue === '' || (!isNaN(Number(newValue)) && !newValue.startsWith('-') && newValue.length <= 10)) {
                                        setPriceTicket(newValue !== '' ? Number(newValue) : null);
                                    }
                                }}
                                InputProps={{
                                    inputProps: {
                                        maxLength: 10
                                    }
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="filter__line filter__line-2 mt-16">
                        <span></span>
                        <FormControl size="small" className="form__control">
                            <TextField
                                label="ID Centro de Costos"
                                id="outlined-size-small"
                                size="small"
                                type="text"
                                value={IDCentroDeCostos}
                                onChange={(ev) => {
                                    const newValue = ev.target.value;
                                    setIDCentroDeCostos(newValue);
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="filter__line filter__line-2 mt-16">
                        <span></span>
                        <FormControl size="small" className="form__control">
                            <TextField
                                label="Articulo"
                                id="outlined-size-small"
                                size="small"
                                type="text"
                                value={articulo}
                                onChange={(ev) => {
                                    const newValue = ev.target.value;
                                    setArticulo(newValue);
                                }}
                            />
                        </FormControl>
                    </div>
                </Box>
                <div className="mt-1">
                    <Stack className="edit_Display_Button" direction="row" spacing={2}>
                        <Button color="error" variant="contained" onClick={onCancel}>
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            disabled={isLoadingUpdate || (isSamePrice && isSameId && isSameArticulo) || !validateForm() || isLoadingUpdateAll}
                            startIcon={isLoadingUpdate || isLoadingUpdateAll ? <CircularProgress size={20} color="inherit" /> : null}
                            onClick={onSubmit}
                        >
                            Confirmar
                        </Button>
                    </Stack>
                </div>
            </Box>
        </Modal>
    );
};
