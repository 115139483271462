import React from 'react';
import img from '../../../assets/img/forbidden.gif';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import './unauthorized.scss';

export const Unauthorized = () => {
    const { t } = useTranslation();
    return (
        <Box className='unauthorized_container'>
            <Box className='unauthorized_content' >
                <Typography className='unauthorized_title'>{t('errors.unauthorizedTitle')}</Typography>
                <Typography className='unauthorized_subtitle1'>{t('errors.unauthorizedDescription')}</Typography>
            </Box>
            <img src={img} alt='' loading='lazy'/>
        </Box>
    );
};
