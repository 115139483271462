import { Modality, Resource } from '../models/resource.model';
import { axiosInstance } from './api.service';

export const billingProductService = {
    getBillingProductlData: async (page: number = 0, pageSize: number = 10, filters?: any) => {
        const params = {
            idCarrera: filters.idCarrera,
            identificador: filters.identificador,
            articulo: filters.article,
            modalidad: filters.modalidad,
            tipoTicket: filters.ticket,
            unidOp: filters.unidOp,
            idTipoModalidad: filters.idTipoModalidad,
            idTurnoCursado: filters.turno,
            pageNo: page.toString(),
            pageSize: pageSize.toString()
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.get(`/codigosCalipso/codigo-calipso?${query}`);
        return data;
    },
    getStudyClassCalipso: async () => {
        const { data } = await axiosInstance.get('/resources/modalidadesCalipso');
        const dataMap = data.map((item: Modality) => ({ id: item.id, description: item.descripcion, modalidad: item.modalidad }));
        return dataMap;
    },
    getModalityType: async (params: string) => {
        const { data } = await axiosInstance.get(`/resources/modalidadesCalipsoPorTipo?tipoModalidad=${params}`);
        const dataMap = data.map((item: Modality) => ({ id: item.id, description: item.descripcion }));
        return dataMap;
    },
    getOperativeUnit: async () => {
        const { data } = await axiosInstance.get('/resources/unidadOperativa');
        const dataMap = data.map((item: Resource) => ({ id: item.id, description: item.descripcion }));
        return dataMap;
    },
    createBillingProduct: async function (billingProduct: any) {
        const { data } = await axiosInstance.post('/codigosCalipso/saveCodigos', billingProduct);
        return data;
    },
    updateBillingProduct: async function (billingProduct: any) {
        const { data } = await axiosInstance.put('/codigosCalipso/updateCodigos', billingProduct);
        return data;
    },
    deleteBillingProduct: async function (billingProduct: any) {
        const { data } = await axiosInstance.put('/codigosCalipso/eliminarCodigos', billingProduct);
        return data;
    }
};
