import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CircularProgress, TableFooter, TablePagination, Button } from '@mui/material';
import TableMessage from '../../../../shared/tableMessage/TableMessage';
import TablePaginationActions from '../../../../shared/pagination/TablePaginationActions';
import RowTableLogDisplay from './row-table-log-display/row-table-log-display';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { bulkLoadService } from '../../../../../services/bulk-load.service';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../../App.context';
import { appReducerCases } from '../../../../../reducers/types';
import { Download } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import { STATUS_ARTICLE } from '../../../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const TableLogDisplay = ({
    articles,
    totalElements,
    totalElementsStatusDraft,
    isLoading,
    isFetching,
    page,
    handlePage,
    pageSize,
    handleItemSelected,
    partialCheck,
    isCheckedAll,
    handleCheckedAll,
    articlesSelecteds,
    handleDownloadCsvAPI,
    handleCleanFilter,
    csvId,
    filters
}: any) => {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const refs = useRef<any | null>(null);
    const navigate = useNavigate();

    const headers = [
        { label: 'CARRERA', key: 'idCarrera' },
        { label: 'MODALIDAD', key: 'modality' },
        { label: 'TIPO_MODALIDAD', key: 'idTipoModalidad' },
        { label: 'TIPO_DE_TICKET', key: 'typeTicket' },
        { label: 'TIPO_ARANCEL', key: 'tipoArancelId' },
        { label: 'PORCENTAJE', key: 'porcentaje' },
        { label: 'TURNO', key: 'turno' },
        { label: 'TURNO_CURSADO_CARRERA', key: 'turnoCursado' },
        { label: 'CAU', key: 'cau' },
        { label: 'PRECIO', key: 'precio' },
        { label: 'MONEDA', key: 'moneda' },
        { label: 'CANTIDAD_DE_MATERIAS', key: 'cantidadMaterias' },
        { label: 'TIPO_DE_ALUMNO', key: 'tipoALumno' },
        { label: 'CB_OI', key: 'cboi' }
    ];

    const aproveCall = async (articles: any) => {
        const data = await bulkLoadService.approvePrice(articles);
        return data;
    };
    const { mutate: aprove, isLoading: isLoadingAprove } = useMutation(aproveCall, {
        onSuccess: (res) => {
            handleCleanFilter();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editUpdateOK')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' (' + error.code + ')'
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const aproveCallAll = async (articlesIds: number[]) => {
        const data = await bulkLoadService.approveAll(csvId, articlesIds, filters);
        return data;
    };
    const { mutate: aproveAll, isLoading: isLoadingAproveAll } = useMutation(aproveCallAll, {
        onSuccess: (res) => {
            navigate('/bulk-load-validation');
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editUpdateAllOK')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' (' + error.code + ')'
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const rejectCall = async (articles: any) => {
        const data = await bulkLoadService.rejectPrice(articles);
        return data;
    };
    const { mutate: reject, isLoading: isLoadingReject } = useMutation(rejectCall, {
        onSuccess: (res) => {
            handleCleanFilter();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editUpdateOK')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' (' + error.code + ')'
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const rejectAllCall = async (articlesIds: number[]) => {
        const data = await bulkLoadService.rejectAll(csvId, articlesIds, filters);
        return data;
    };
    const { mutate: rejectAll, isLoading: isLoadingRejectAll } = useMutation(rejectAllCall, {
        onSuccess: (res) => {
            navigate('/bulk-load-validation');
            handleCleanFilter();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editDeclineUpdateAllOK')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' (' + error.code + ')'
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const eliminateCall = async (articles: any) => {
        const data = await bulkLoadService.eliminatePrice(articles);
        return data;
    };
    const { mutate: eliminate, isLoading: isLoadingEliminate } = useMutation(eliminateCall, {
        onSuccess: (res) => {
            handleCleanFilter();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editUpdateOK')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' (' + error.code + ')'
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const eliminateAllCall = async (articlesIds: number[]) => {
        const data = await bulkLoadService.deleteAll(csvId, articlesIds, filters);
        return data;
    };
    const { mutate: eliminateAll, isLoading: isLoadingEliminateAll } = useMutation(eliminateAllCall, {
        onSuccess: (res) => {
            navigate('/bulk-load-validation');
            handleCleanFilter();
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('messages.editRemovingUpdateAllOK')
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        },
        onError: (error: any) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: t('errors.error') + ' (' + error.code + ')'
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'error'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
        }
    });

    const createNewObject = (object: any) => {
        return {
            id: object.idArticle,
            idProgram: object.idCarrera,
            descriptionProgram: object.descripcionCarrera,
            modality: object.modality,
            typeModality: object.tipoModalidad,
            idTypeModality: object.idTipoModalidad,
            cbOi: object.cboi,
            typeTicket: object.typeTicket,
            typeArancel: object.tipoArancel,
            idTypeArancel: object.tipoArancelId,
            percentage: object.porcentaje,
            rubro: object.rubro,
            productBilling: object.productBilling,
            turn: object.turno,
            turnCourse: object.turnoCursado,
            cau: object.cau,
            idCau: object.idCau,
            price: object.precio,
            academicPeriod: object.periodoAcademico,
            money: object.moneda,
            amountCourse: object.cantidadMaterias,
            typeStudent: object.tipoALumno,
            idTypeTicket: object.idTipoTicket,
            startDate: object.fechaInicio?.substring(0, object.fechaInicio?.indexOf('T')),
            csvId: object.csvId,
            article: object.articulo,
            loadDate: object.fechaCarga && moment(object.fechaCarga).utc(),
            estadoDeCarga: object.estadoCarga,
            observacion: object.obesrvaciones,
            estado: object.estado,
            idEstado: object.idEstado,
            usuario: object.usuario,
            insId: object.insId,
            unidOp: object.unidOp
        };
    };

    const messageNoSelected = () => {
        appContext.dispatch({
            type: appReducerCases.setSnackbarMessage,
            payload: t('messages.noSelected')
        });
        appContext.dispatch({
            type: appReducerCases.setSnackbarSeverity,
            payload: 'info'
        });
        appContext.dispatch({
            type: appReducerCases.openSnackbar,
            payload: true
        });
    };

    const handleDownloadCSV = () => {
        if (!isCheckedAll) {
            refs.current.link.click();
        } else {
            handleDownloadCsvAPI();
        }
    };

    const handleAprove = () => {
        appContext.dispatch({
            type: appReducerCases.setConfirmButtonString,
            payload: t('buttons.confirm')
        });
        appContext.dispatch({
            type: appReducerCases.setCancelButtonString,
            payload: t('buttons.cancel')
        });
        appContext.dispatch({
            type: appReducerCases.setAlertConfirmAction,
            payload: () => {
                if (isCheckedAll) {
                    const articles = articlesSelecteds.filter((art: any) => !art.checked && art.idEstado === STATUS_ARTICLE.DRAFT);
                    const selectedsIds = articles.map((item: any) => item.idArticle);
                    aproveAll(selectedsIds);
                } else {
                    const articlesCheck = articlesSelecteds.filter((art: any) => art.checked && art.idEstado === STATUS_ARTICLE.DRAFT);
                    const articlesNew = articlesCheck.map(createNewObject);
                    if (articlesNew.length > 0) {
                        // exist(articlesNew);
                        aprove(articlesNew);
                    } else {
                        messageNoSelected();
                    }
                }
            }
        });
        appContext.dispatch({
            type: appReducerCases.setAlertCancelAction,
            payload: () => {}
        });
        appContext.dispatch({
            type: appReducerCases.openAlert,
            payload: t('messages.aprovedConfirm', {
                csv: articles[0]?.csvId
            })
        });
    };

    const handleRejectItem = () => {
        appContext.dispatch({
            type: appReducerCases.setConfirmButtonString,
            payload: t('buttons.confirm')
        });
        appContext.dispatch({
            type: appReducerCases.setCancelButtonString,
            payload: t('buttons.cancel')
        });
        appContext.dispatch({
            type: appReducerCases.setAlertConfirmAction,
            payload: () => {
                if (isCheckedAll) {
                    const articles = articlesSelecteds.filter((art: any) => !art.checked && art.idEstado === STATUS_ARTICLE.DRAFT);
                    const selectedsIds = articles.map((item: any) => item.idArticle);
                    rejectAll(selectedsIds);
                } else {
                    const articlesCheck = articlesSelecteds.filter((art: any) => art.checked && art.idEstado === STATUS_ARTICLE.DRAFT);
                    const articlesNew = articlesCheck.map(createNewObject);
                    if (articlesNew.length) {
                        reject(articlesNew);
                    } else {
                        messageNoSelected();
                    }
                }
            }
        });
        appContext.dispatch({
            type: appReducerCases.setAlertCancelAction,
            payload: () => {}
        });
        appContext.dispatch({
            type: appReducerCases.openAlert,
            payload: t('messages.rejectConfirm', {
                csv: articles[0]?.csvId
            })
        });
    };

    const handleDeleteItem = () => {
        appContext.dispatch({
            type: appReducerCases.setConfirmButtonString,
            payload: t('buttons.confirm')
        });
        appContext.dispatch({
            type: appReducerCases.setCancelButtonString,
            payload: t('buttons.cancel')
        });
        appContext.dispatch({
            type: appReducerCases.setAlertConfirmAction,
            payload: () => {
                if (isCheckedAll) {
                    const articles = articlesSelecteds.filter((art: any) => !art.checked && art.idEstado === STATUS_ARTICLE.DRAFT);
                    const selectedsIds = articles.map((item: any) => item.idArticle);
                    eliminateAll(selectedsIds);
                } else {
                    const articlesCheck = articlesSelecteds.filter((art: any) => art.checked && art.idEstado === STATUS_ARTICLE.DRAFT);
                    const articlesNew = articlesCheck.map(createNewObject);
                    if (articlesNew.length) {
                        eliminate(articlesNew);
                    } else {
                        messageNoSelected();
                    }
                }
            }
        });
        appContext.dispatch({
            type: appReducerCases.setAlertCancelAction,
            payload: () => {}
        });
        appContext.dispatch({
            type: appReducerCases.openAlert,
            payload: t('messages.deleteConfirm', {
                csv: articles[0]?.csvId
            })
        });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, currenPage: number) => {
        handlePage(currenPage);
    };

    if (isLoading || isFetching) {
        return (
            <div className="flex-justify-content_center">
                <CircularProgress id="rmt-loadingPrograms" color="success" />
            </div>
        );
    }

    if (articles?.length === 0) return <TableMessage msg={t('messages.resultsNotFount')} />;

    return (
        <>
            {(articlesSelecteds.some((item: any) => item?.checked) ||
                (isCheckedAll && articlesSelecteds.some((item: any) => item.idEstado === STATUS_ARTICLE.DRAFT))) && (
                <>
                    <Button
                        variant="contained"
                        className="table_logDisplay_button"
                        type="button"
                        startIcon={<CheckCircleOutlineIcon />}
                        onClick={(e) => handleAprove()}
                    >
                        {t('buttons.approve')}
                    </Button>
                    <Button
                        variant="contained"
                        className="table_logDisplay_button"
                        color="error"
                        type="button"
                        startIcon={<HighlightOffIcon />}
                        onClick={handleRejectItem}
                    >
                        {t('buttons.decline')}
                    </Button>
                    <Button
                        variant="outlined"
                        className="table_logDisplay_button"
                        type="button"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteItem}
                    >
                        {t('buttons.delete')}
                    </Button>
                    <Button variant="contained" type="button" startIcon={<Download />} onClick={handleDownloadCSV}>
                        {t('buttons.download')}
                    </Button>
                    <CSVLink
                        data={articlesSelecteds.filter((item: any) => item.checked)}
                        headers={headers}
                        filename={'lista_precios_carga_masiva.csv'}
                        ref={refs}
                        separator={';'}
                        enclosingCharacter={''}
                        uFEFF={false}
                    />
                    {isCheckedAll ? (
                        <div className="mt-1">
                            <small>
                                <b>
                                    {totalElementsStatusDraft -
                                        articlesSelecteds.filter((art: any) => !art.checked && art.idEstado === STATUS_ARTICLE.DRAFT).length}{' '}
                                    registros seleccionados
                                </b>
                            </small>
                        </div>
                    ) : (
                        <div className="mt-1">
                            <small>
                                <b>{articlesSelecteds.filter((item: any) => item.checked).length} registros seleccionados</b>
                            </small>
                        </div>
                    )}
                </>
            )}

            {(isLoadingAprove || isLoadingReject || isLoadingEliminate || isLoadingRejectAll || isLoadingEliminateAll || isLoadingAproveAll) && (
                <div className="flex-justify-content_center loading">
                    <CircularProgress id="rmt-loadingPrograms" color="success" />
                </div>
            )}
            <TableContainer className="table__price_list" component={Paper}>
                <Table>
                    <TableBody className="table__body">
                        <TableRow className="table__log_Display_Header">
                            <TableCell className="tableCell__log_Display_Header">
                                <Checkbox color="default" checked={isCheckedAll} indeterminate={partialCheck} onChange={handleCheckedAll} />
                            </TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.csv')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.career')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.cau')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.modality')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.period')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.ticket')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.Shift')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.status')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.typeStudent')}</TableCell>
                            <TableCell className="table__courseProgramCell">{t('tableCell.price')}</TableCell>
                        </TableRow>
                        {articles.map((item: any, i: number) => (
                            <RowTableLogDisplay key={i} item={item} handleItemSelected={handleItemSelected} handleCleanFilter={handleCleanFilter} />
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={totalElements}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`;
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};
export default TableLogDisplay;
