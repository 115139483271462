import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ModalI {
    openModal: boolean;
    closeModal: Function | any;
    message: string;
    cancelButtonString?: string;
    cancelButtonFunction?: Function;
    confirmButtonString?: string;
    confirmButtonFunction?: Function;
}

function ModalComponent({
    openModal,
    closeModal,
    message,
    cancelButtonString = 'buttons.cancel',
    cancelButtonFunction = () => {},
    confirmButtonString = 'buttons.confirm',
    confirmButtonFunction = () => {}
}: ModalI) {
    const { t } = useTranslation();
    useEffect(() => {
        const close = (e: any) => {
            if (e.keyCode === 27) {
                closeModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);

    return (
        <div>
            <Modal open={openModal} onClose={closeModal}>
                <Box className="modal__box">
                    <div className="modal__message">{message}</div>
                    <div className="modal__buttons">
                        <Button
                            className="modal__cancelButton"
                            id="rmt-modalCancelButton"
                            variant="contained"
                            color="error"
                            onClick={() => {
                                cancelButtonFunction();
                            }}
                        >
                            {t(cancelButtonString)}
                        </Button>
                        <Button
                            className="modal__confirmButton"
                            variant="contained"
                            id="rmt-modalConfirmButton"
                            onClick={() => {
                                confirmButtonFunction();
                            }}
                        >
                            {t(confirmButtonString)}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ModalComponent;
