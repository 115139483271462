/* eslint-disable no-unused-vars */
import React from 'react';
import { IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { formatDateET } from '../../../../../../utils/formatDate';
import { Edit as EditIcon } from '@mui/icons-material';
import { formatPrice } from '../../../../../../utils/formatPrice';
interface rowTicketsGraduationInterface {
    item: any;
    handleEditTicket: Function;
    isRoleCargaPrecio?: boolean;
}

const studyClassDictionary: any = {
    19: 'CURSOS CLOUD 21',
    15: 'CURSOS EVENTOS Y WORKS',
    14: 'CURSOS INTERNOS',
    48: 'DEFAULT DISTANCIA',
    17: 'DIPLOMADOS CLOUD 21',
    45: 'MAESTRIA VIRTUAL',
    13: 'MAESTRIAS',
    0: 'OTROS WEB',
    49: 'PosMaestria DISTANCIA',
    47: 'PREGRADO PRESENCIAL',
    16: 'PRESENCIAL CENCAP',
    25: 'SECUNDARIO',
    18: 'CERTIFICADOS CLOUD 21',
    2: 'DIPLOMATURA MTD',
    10: 'EXTRAGRADO',
    1: 'GRADO MTD',
    7: 'GRADO PRESENCIAL',
    20: 'GRADO PRESENCIAL .',
    4: 'GRADO PRESENCIAL HOME',
    11: 'PREGRADO',
    46: 'ACTIVIDAD DISTANCIA'
};

export const RowTableExchangeTicketsGraduation = ({ item, handleEditTicket, isRoleCargaPrecio }: rowTicketsGraduationInterface) => {
    return (
        <TableRow sx={{ boxShadow: 2, textAlign: 'center' }} className="table__mainRow">
            <TableCell className="table__cellAux">
                <Table>
                    <TableBody>
                        <TableRow
                            className="table__row table__row_exchange_graduation_price"
                            hover={true}
                            sx={{ '& > *': { borderBottom: 'unset' } }}
                        >
                            <TableCell className="table__exchange_tickets_CellBody">
                                {item.idStudyClass + ' - ' + studyClassDictionary[item.idStudyClass]}
                            </TableCell>
                            <TableCell className="table__exchange_tickets_CellBody">{item.centroDeCostos}</TableCell>
                            <TableCell className="table__exchange_tickets_CellBody">{item.articulo}</TableCell>
                            <TableCell className="table__exchange_tickets_CellBody">{formatPrice(item.price)}</TableCell>
                            <TableCell className="table__exchange_tickets_CellBody">{formatDateET(item.fechaDesde)}</TableCell>
                            <TableCell className="table__exchange_tickets_CellBody">{item.usuario}</TableCell>
                            <TableCell className="table__exchange_tickets_CellBody">
                                <Tooltip title="Editar">
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        id="rmt-syllabusEdit"
                                        onClick={() => handleEditTicket(item)}
                                        disabled={!isRoleCargaPrecio}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableCell>
        </TableRow>
    );
};
