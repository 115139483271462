/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */

import { Autocomplete, Box, Button, CircularProgress, FormControl, Modal, Stack, TextField, Typography } from '@mui/material';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { exchangeTicketsService } from '../../../../../services/exchange-tickets.service';
import { AppContext } from '../../../../../App.context';
import { appReducerCases } from '../../../../../reducers/types';

interface FormInterface {
    open: boolean;
    onClose: any;
    refetch: any;
    user: any;
}

const FormGraduationExchangeTickets = ({ open, onClose, refetch, user }: FormInterface) => {
    const [price, setPrice] = useState<any>(0);
    const [IDCentroDeCostos, setIDCentroDeCostos] = useState<string>('');
    const [articulo, setArticulo] = useState<string>('');
    const [studyClass, setStudyClass] = useState<any>(null);
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const { data: studyClassData, refetch: refetchStudyClass } = useQuery(
        'studyClassET',
        async () => {
            const data = exchangeTicketsService.getStudyClassWithoutPrice();
            return data;
        },
        {
            refetchOnWindowFocus: false
        }
    );

    const createTicketGraduationCall = async (ticket: any) => {
        const data = await exchangeTicketsService.postGraduationTicket(ticket);
        return data;
    };

    const { mutate: createTicketGraduation, isLoading } = useMutation(createTicketGraduationCall, {
        onSuccess: (res) => {
            appContext.dispatch({
                type: appReducerCases.setSnackbarMessage,
                payload: 'El ticket fue creado con correctamente'
            });
            appContext.dispatch({
                type: appReducerCases.setSnackbarSeverity,
                payload: 'success'
            });
            appContext.dispatch({
                type: appReducerCases.openSnackbar,
                payload: true
            });
            handleClose();
            refetchStudyClass();
            refetch();
        }
    });

    const validateForm = () => {
        return studyClass && price && IDCentroDeCostos;
    };

    const handleClose = () => {
        setPrice(0);
        setStudyClass(null);
        setIDCentroDeCostos('');
        setArticulo('');
        onClose();
    };

    const onSubmit = () => {
        const ticket = {
            price,
            user: user.name,
            id: studyClass.id,
            desc: studyClass.description,
            centroCosto: IDCentroDeCostos,
            articulo: articulo
        };
        createTicketGraduation(ticket);
    };

    return (
        <>
            <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 550,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 4
                    }}
                >
                    <Box className="content_sale_tool">
                        <Typography className="module_title" data-testid="module-title" gutterBottom>
                            {'Crear Ticket'}
                        </Typography>
                    </Box>

                    <Box>
                        <div className="filter__line">
                            <FormControl className="form__control">
                                <Autocomplete
                                    clearText="Limpiar"
                                    openText=""
                                    value={studyClass}
                                    options={studyClassData || []}
                                    onChange={(e: any, newValue: any | null) => setStudyClass(newValue)}
                                    disablePortal
                                    noOptionsText={t('labels.noOptionsText')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option?.id + ' - ' + option?.description}
                                    renderInput={(params) => (
                                        <TextField key={params.id} value={params.id} {...params} label={'Clase de Estudio'} size="small" />
                                    )}
                                    renderOption={(props, option: any) => (
                                        <li {...props} value={option?.id} key={option?.id}>
                                            {option?.id + ' - ' + option?.description}
                                        </li>
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className="filter__line mt-1">
                            <FormControl size="small" className="form__control">
                                <TextField
                                    label="Precio"
                                    id="outlined-size-small"
                                    size="small"
                                    type="text"
                                    onClick={() => setPrice('')}
                                    value={price !== null ? price.toString() : ''}
                                    onChange={(ev: any) => {
                                        const newValue = ev.target.value.replace(/\D/g, ''); // Solo acepta caracteres numéricos
                                        if (
                                            newValue === '' ||
                                            (!isNaN(Number(newValue)) && !newValue.startsWith('-') && newValue.length <= 10) ||
                                            (!isNaN(Number(newValue)) && !newValue.startsWith('+') && newValue.length <= 10)
                                        ) {
                                            setPrice(newValue !== '' ? Number(newValue) : null);
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            maxLength: 10
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="filter__line mt-1">
                            <FormControl size="small" className="form__control">
                                <TextField
                                    label="ID Centro De Costos"
                                    id="outlined-size-small"
                                    size="small"
                                    type="text"
                                    value={IDCentroDeCostos}
                                    onChange={(ev: any) => {
                                        const newValue = ev.target.value;
                                        setIDCentroDeCostos(newValue);
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="filter__line mt-1">
                            <FormControl size="small" className="form__control">
                                <TextField
                                    label="Articulo"
                                    id="outlined-size-small"
                                    size="small"
                                    type="text"
                                    value={articulo}
                                    onChange={(ev: any) => {
                                        const newValue = ev.target.value;
                                        setArticulo(newValue);
                                    }}
                                />
                            </FormControl>
                        </div>
                    </Box>
                    <div className="mt-1">
                        <Stack className="edit_Display_Button" direction="row" spacing={2}>
                            <Button color="error" variant="contained" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={isLoading || !validateForm()}
                                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                                onClick={onSubmit}
                            >
                                Confirmar
                            </Button>
                        </Stack>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default FormGraduationExchangeTickets;
