import { axiosInstance } from './api.service';

export const historicalService = {
    getHistoricalData: async (page: number = 0, pageSize: number = 10, filters?: any) => {
        const params = {
            idCsv: filters.csvId,
            idCau: filters.cauId,
            tipoAlumno: filters.studentTypeId,
            idTipoTicket: filters.ticketId,
            tipoArancelId: filters.arancelId,
            idCarrera: filters.careerId,
            idTipoModalidad: filters.modalityId,
            periodoAcademico: filters.periodId,
            turnoCursado: filters.turnoId,
            fechaMax: filters.endDate,
            fechaMin: filters.startDate,
            idEstado: filters.statusId,
            pageNo: page.toString(),
            pageSize: pageSize.toString()
        };
        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        const { data } = await axiosInstance.get(`/articles/historicPrices?${query}`);
        return data;
    },
    downloadCsvPriceList: (articlesIds: number[], filters?: any, status: any = 6) => {
        const params = {
            idCsv: filters.csvId,
            idCarrera: filters.careerId,
            idCau: filters.cauId,
            idTipoModalidad: filters.modalityId,
            periodoAcademico: filters.periodId,
            tipoArancelId: filters.arancelId,
            idTipoTicket: filters.ticketId,
            turnoCursado: filters.turnoId,
            tipoAlumno: filters.studentTypeId,
            fechaMin: filters.startDate,
            fechaMax: filters.endDate,
            idEstadoHistorico: filters.statusId,
            idEstado: status
        };

        const map: any = new Map(Object.entries(params));
        for (const [key, value] of map) {
            if (!value) {
                map.delete(key);
            }
        }
        const obj = Object.fromEntries(map);
        const searchParams = new URLSearchParams(obj);
        const query = searchParams.toString();
        return axiosInstance({
            url: `/articles/current-prices/export-csv?${query}`,
            method: 'POST',
            data: JSON.stringify(articlesIds),
            responseType: 'blob',
            headers: { 'Content-Type': 'application/json' }
        });
    }
};
