import React, { useEffect, useReducer, useState } from 'react';
import './styles/styles.scss';
import API from './services/api.service';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AppContext, initialState } from './App.context';
import { createTheme, ThemeProvider, Snackbar, Alert } from '@mui/material';
import { themeOptions } from './theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import appReducer from './reducers/app.reducer';
import AppRoutes from './routes/App.routes';
import { appReducerCases } from './reducers/types';
import { ROLES } from './utils/constants';
import ModalComponent from './components/shared/modal/ModalComponent';
import { Unauthorized } from './components/shared/unauthorized/unauthorized';

const getRoles = (user: any = {}) => {
    const authorities = user && user.authorities ? user.authorities : [];
    const isValidUser = authorities.some((autor: any) => ROLES.includes(autor.authority));
    return { authorities, isValidUser };
};

const queryClient = new QueryClient();

const App = () => {
    const [appData, setAppData] = useState<any>({
        isLoading: true
    });
    const [data, dispatch] = useReducer(appReducer, initialState);
    const theme = createTheme(themeOptions);

    useEffect(() => {
        initApp();
    }, []);

    const initApp = async () => {
        setAppData({ isLoading: true });
        const newAppData = await API.initApp();
        const isLogout = window.location.pathname.includes('logout');
        if (!isLogout && !newAppData.user) API.goToLogin();

        const userRoles = getRoles(newAppData.user);
        dispatch({
            type: appReducerCases.setUser,
            payload: newAppData.user
        });
        setAppData({
            ...newAppData,
            isLoading: false,
            userRoles: userRoles.authorities,
            isValidUser: userRoles.isValidUser
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <AppContext.Provider
                        value={{
                            state: data,
                            dispatch
                        }}
                    >
                        {!appData.isValidUser && !appData.isLoading && (
                            <Routes>
                                <Route path="*" element={<Unauthorized />} />
                            </Routes>
                        )}
                        {appData.isValidUser && !appData.isLoading && <AppRoutes appData={appData} />}
                        <Snackbar
                            open={data.snackbar?.isOpen}
                            autoHideDuration={8000}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            onClose={() => {
                                dispatch({
                                    type: appReducerCases.closeSnackbar,
                                    payload: false
                                });
                            }}
                        >
                            <Alert severity={data.snackbar?.severity} sx={{ width: '100%', textAlign: 'center' }}>
                                {data.snackbar?.message}
                            </Alert>
                        </Snackbar>
                        <ModalComponent
                            openModal={data.alert?.isOpen}
                            closeModal={() => {
                                dispatch({
                                    type: appReducerCases.closeAlert,
                                    payload: false
                                });
                            }}
                            message={data.alert?.message}
                            cancelButtonFunction={() => {
                                data.alert?.cancelAction();
                                dispatch({
                                    type: appReducerCases.closeAlert,
                                    payload: false
                                });
                            }}
                            confirmButtonFunction={() => {
                                data.alert?.confirmAction();
                                dispatch({
                                    type: appReducerCases.closeAlert,
                                    payload: false
                                });
                            }}
                            confirmButtonString={data.alert?.confirmButtonString}
                            cancelButtonString={data.alert?.cancelButtonString}
                        />
                    </AppContext.Provider>
                </Router>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
