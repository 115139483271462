import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Table, TableBody, TableCell, TableRow, Collapse, Chip, Checkbox } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import { STATUS_HISTORICAL } from '../../../../../utils/constants';
import { formatPrice } from '../../../../../utils/formatPrice';
import { formatDate } from '../../../../../utils/formatDate';

type tableHistorical = {
    item: any;
    handleItemSelected: any;
};

const RowTableHistorical = ({ item, handleItemSelected }: tableHistorical) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const { t } = useTranslation();
    const [color, setColor] = useState<'success' | 'warning' | 'error' | 'default'>('default');

    useEffect(() => {
        let color: 'success' | 'warning' | 'error' | 'default' = 'default';
        switch (item.estado) {
            case STATUS_HISTORICAL.APPROVED:
                color = 'success';
                break;
            case STATUS_HISTORICAL.DRAFT:
                color = 'warning';
                break;
            case STATUS_HISTORICAL.REFUSED:
                color = 'error';
                break;
            default:
                color = 'default';
                break;
        }
        setColor(color);
    }, [item]);

    return (
        <>
            <TableRow sx={{ boxShadow: 2, textAlign: 'center' }} className="table__mainRow">
                <TableCell className="table__cellAux">
                    <Table>
                        <TableBody>
                            <TableRow className="table__row table__historicalBodyArticles" hover={true} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell className="table__cell table__controlCell">
                                    <Checkbox checked={item?.checked} onChange={(e) => handleItemSelected(e, item)} />
                                </TableCell>
                                <TableCell className="table__historicalCellBody">{item.csvId}</TableCell>
                                <TableCell className="table__historicalCellBody">
                                    {item.idProgram} - {item.descriptionProgram}
                                </TableCell>
                                <TableCell className="table__historicalCellBody">{item.cau}</TableCell>
                                <TableCell className="table__historicalCellBody">{item.typeModality}</TableCell>
                                <TableCell className="table__historicalCellBody">{item.academicPeriod}</TableCell>
                                <TableCell className="table__historicalCellBody">{item.typeTicket}</TableCell>
                                <TableCell className="table__historicalCellBody">{item.turnCourse} - {item.turnoCursado}</TableCell>
                                <TableCell className="table__historicalCellBody">{item.typeStudent}</TableCell>
                                <TableCell className="table__historicalCellBody">
                                    <Chip label={item.estado} color={color} />
                                </TableCell>
                                <TableCell className="table__cell table__controlCell">
                                    {isAccordionOpen ? (
                                        <IconButton
                                            aria-label="rmt-courseExpand"
                                            size="small"
                                            id="rmt-courseExpand"
                                            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                                        >
                                            <KeyboardArrowUpIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="rmt-courseExpand"
                                            size="small"
                                            id="rmt-courseExpand"
                                            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                                        >
                                            <KeyboardArrowDownIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ width: '100%', display: 'grid' }}>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={6}>
                                    <Collapse in={isAccordionOpen} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: 0 }}>
                                            <Table size="small" sx={{ borderSpacing: '0px 0px !important' }}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="accordion">
                                                            <Box className="accordion_content">
                                                                <Box className="accordion_item">
                                                                    <p className="title-detail-classroom">{t('tableCell.registrationData')}</p>
                                                                    <Box className="row">
                                                                        <Box className="col col-35">
                                                                            <p className="subtitle-detail-1">{t('tableCell.dateC')}</p>
                                                                            <Box className="mt-1">{formatDate(item.loadDate)}</Box>
                                                                        </Box>
                                                                        <Box className="col col-35">
                                                                            <p className="subtitle-detail-1">{t('tableCell.idArticle')}</p>
                                                                            <Box className="mt-1">{item.id}</Box>
                                                                        </Box>
                                                                        <Box className="col col-30">
                                                                            <p className="subtitle-detail-1">{t('tableCell.modalityDes')}</p>
                                                                            <Box className="mt-1">{item.typeModality}</Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="row">
                                                                        <Box className="col col-35">
                                                                            <p className="subtitle-detail-1">{t('tableCell.percentage')}</p>
                                                                            <Box className="mt-1">{item.percentage}</Box>
                                                                        </Box>
                                                                        <Box className="col col-35">
                                                                            <p className="subtitle-detail-1">{t('tableCell.numberSubjects')}</p>
                                                                            <Box className="mt-1">{item.amountCourse}</Box>
                                                                        </Box>
                                                                        <Box className="col col-30">
                                                                            <p className="subtitle-detail-1">{t('tableCell.cboi')}</p>
                                                                            <Box className="mt-1">{item.cb_oi}</Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="row">
                                                                        <Box className="col col-35">
                                                                            <p className="subtitle-detail-1">{t('tableCell.dateA')}</p>
                                                                            <Box className="mt-1">{formatDate(item.approvalDate)}</Box>
                                                                        </Box>
                                                                        <Box className="col col-35">
                                                                            <p className="subtitle-detail-1">{t('tableCell.price')}</p>
                                                                            <Box className="mt-1">{formatPrice(item.price)}</Box>
                                                                        </Box>
                                                                        <Box className="col col-30">
                                                                            <p className="subtitle-detail-1">{t('tableCell.tariff')}</p>
                                                                            <Box className="mt-1">{item.typeArancel}</Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    );
};

export default RowTableHistorical;
