import React from 'react';
import { IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { formatDateET } from '../../../../../../utils/formatDate';
import { Edit as EditIcon } from '@mui/icons-material';
import { formatPrice } from '../../../../../../utils/formatPrice';
interface rowTicketsPriceInterface {
    item: any;
    handleEditTicket: Function;
    isRoleCargaPrecio?: boolean;
}

export const RowTableExchangeTicketsPrice = ({ item, handleEditTicket, isRoleCargaPrecio }: rowTicketsPriceInterface) => {
    return (
        <>
            <TableRow sx={{ boxShadow: 2, textAlign: 'center' }} className="table__mainRow">
                <TableCell className="table__cellAux">
                    <Table>
                        <TableBody>
                            <TableRow
                                className="table__row table__row_exchange_tickets_price"
                                hover={true}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                <TableCell className="table__exchange_tickets_CellBody">{item.cantMaterias}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">{formatPrice(item.price)}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">{formatDateET(item.fechaDesde)}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">{item.usuario}</TableCell>
                                <TableCell className="table__exchange_tickets_CellBody">
                                    <Tooltip title="Editar">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            id="rmt-syllabusEdit"
                                            onClick={() => handleEditTicket(item)}
                                            disabled={!isRoleCargaPrecio}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    );
};
